import { uniqueString } from 'common/helpers/data.js';

export const CARDS = {
  PLACE_BASIC_CANNON: uniqueString('C'),
  PLACE_SHORT_RANGE_CANNON: uniqueString('C'),
  PLACE_RANDOM_CANNON_RANDOMLY: uniqueString('C'),
  PLACE_SNIPER_CANNON: uniqueString('C'),
  PLACE_DEFENDER: uniqueString('C'),

  PLACE_WAREHOUSE: uniqueString('C'),
  PLACE_POWER_STATION: uniqueString('C'),
  PLACE_FORGE: uniqueString('C'),

  PLACE_MINES_RANDOMLY: uniqueString('C'),
  PLACE_MINE: uniqueString('C'),

  IMPROVE_HEADQUARTER: uniqueString('C'),
  ACTIVATE_CANNONS: uniqueString('C'),

  DESTROY_BUILDING: uniqueString('C'),

  ADD_ENERGY: uniqueString('C'),
  SPARK: uniqueString('C'),
  INVESTMENT: uniqueString('C'),
  INCREASE_ENERGY_PRODUCTION: uniqueString('C'),
  REDUCE_DRAIN_RANDOMLY: uniqueString('C'),

  TRASH_CARD: uniqueString('C'),
  TRASH_AND_COPY_CARD: uniqueString('C'),

  DRAW_CARDS: uniqueString('C'),

  INCREASE_POWER: uniqueString('C'),
  INCREASE_MULTISHOT: uniqueString('C'),

  SPREAD_SHOT: uniqueString('C'),
  SPREAD_SHOT_IMPROVING: uniqueString('C'),
  SINGLE_FIRE: uniqueString('C'),
  SNIPER_FIRE: uniqueString('C'),
  SNIPER_SHOT_IMPROVING: uniqueString('C'),
  MULTISHOT: uniqueString('C'),
  ENERGY_TO_BULLETS: uniqueString('C'),
  FAIR_SHOT: uniqueString('C'),
  POWER_SHOT: uniqueString('C'),

  POISON_TO_ALL: uniqueString('C'),
  VOID: uniqueString('C'),
  VOID_TO_ALL: uniqueString('C'),
  DESTROY_FIELD: uniqueString('C'),

  RANDOM_FORTIFICATION: uniqueString('C'),
  FORTIFICATE_OWN_FIELD: uniqueString('C'),
  FORTIFICATE_BORDERS: uniqueString('C'),
  FORTIFICATE_MAIN: uniqueString('C'),
  FORTIFICATE_AROUND_BASE: uniqueString('C'),
  FORTIFICATE_STRUCTURES: uniqueString('C'),
  FORTIFICATE_AND_PLACE_REINFORCEMENT: uniqueString('C'),

  INCREASE_CARD_LIMIT: uniqueString('C'),

  JUNK: uniqueString('C'),
};
