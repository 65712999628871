import { CARDS } from 'common/consts/types/cards.js';
import { perFrameTimer } from 'common/helpers/converters.js';

import { CheatsMenu } from '../interface/dev/cheats-menu.js';
import { PingInfo } from '../interface/dev/ping-info.js';

const LOCAL_STORAGE_KEY = 'dev-active';

export class DevTools {
  constructor(game) {
    this.game = game;
    this.active = false;

    this.interfacePart = game.interfacePart;
    this.advancedLogicPart = game.advancedLogicPart;
    this.serverPart = game.serverPart;
    this.multiplayerPart = game.multiplayerPart;

    this.pingingTimer = 0;
  }

  setup = () => {
    this.pingInfo = new PingInfo({ game: this.game });
    this.cheatsMenu = new CheatsMenu({ game: this.game });

    if (localStorage.getItem(LOCAL_STORAGE_KEY) === 'true') this.turnOn();
  };

  activate(delta) {
    if (!this.active) return;

    this.calculatePingTimer(delta);
  }

  calculatePingTimer(delta) {
    if (!this.multiplayerPart) return;

    this.pingingTimer -= delta;

    if (this.pingingTimer <= 0) {
      this.pingingTimer = perFrameTimer(1);
      this.game.connectionController.pingServer();
    }
  }

  turnOn = () => {
    this.active = true;
    localStorage.setItem(LOCAL_STORAGE_KEY, true);
    if (!this.multiplayerPart) this.cheatsMenu.setVisibility(true);
    if (this.multiplayerPart) this.pingInfo.turnOn();

    window.Game = this.game;
  };

  turnOff = () => {
    this.active = false;
    localStorage.setItem(LOCAL_STORAGE_KEY, false);
    this.cheatsMenu.setVisibility(false);
    this.pingInfo.turnOff();
  };

  overrideMainKingdomStuff() {
    if (!this.active) return false;

    this.overrideKingdomDeck();
    this.setInstantRedraw();
  }

  overrideKingdomDeck() {
    const cardsTypes = this.overrideCardsTypes();
    const deck = this.game.mainKingdom.deck;
    const hand = this.game.mainKingdom.hand;

    const cards = cardsTypes.map((cardType) => {
      return this.game.cardsCreator.createCard({ type: cardType, owner: deck });
    });

    deck.cards.forEach((card) => card.destroy());

    deck.setCards(cards);
    const oldHandCards = hand.removeAllCards();
    oldHandCards.forEach((oldCard) => oldCard.destroy());
  }

  setInstantRedraw() {
    this.game.mainKingdom.changeCyclesPerRendraw(0);
    this.game.mainKingdom.canRedraw = true;

    this.game.mainKingdom.deck.onRedrawChange();
    this.game.mainKingdom.redrawIcons.onRedrawChange();
  }

  onToggle() {
    if (this.active) {
      this.turnOff();
    } else {
      this.turnOn();
    }
  }

  overrideCardsTypes() {
    return [
      CARDS.PLACE_SNIPER_CANNON,
      CARDS.INCREASE_ENERGY_PRODUCTION,
      CARDS.INCREASE_ENERGY_PRODUCTION,
      CARDS.INCREASE_ENERGY_PRODUCTION,
      CARDS.INCREASE_ENERGY_PRODUCTION,
    ];
  }
}
