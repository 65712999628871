import { FRAMES_PER_SECOND } from 'common/consts/game.js';
import { PLAYING, WAITING } from 'common/consts/statuses.js';

import { InfoText } from './info-text.js';

export class StatusInfo extends InfoText {
  constructor(params) {
    super({ ...params, size: 50 });

    this.status = null;
    this.seconds = null;
  }

  setPosition() {
    this.spritesContainer.x = window.innerWidth / 2;
    this.spritesContainer.y = window.innerHeight / 2;
  }

  activate() {
    const newStatus = this.game.getStatus();

    if (newStatus !== this.status) {
      this.status = newStatus;

      if (newStatus === PLAYING) this.hideMessage();
    }

    if (this.status === WAITING) {
      const timer = this.game.getCountdown();
      if (timer === null) return;

      const seconds = Math.ceil(timer / FRAMES_PER_SECOND);

      if (this.seconds !== seconds) {
        this.seconds = seconds;
        this.showMessage(seconds, 0);
      }
    }
  }
}
