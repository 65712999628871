import { InfoText } from '../info-text.js';

export class PingInfo extends InfoText {
  constructor(params) {
    super({ ...params, size: 20 });

    this.activated = false;
  }

  setPosition() {
    this.spritesContainer.x = 50;
    this.spritesContainer.y = 20;
  }

  activate() {
    if (!this.activated) return false;

    const ping = this.game.connectionController.getPing();

    this.setMessage(ping + 'ms');
  }

  turnOn() {
    this.activated = true;
    this.show();
  }

  turnOff() {
    this.activated = false;
    this.hideMessage();
  }

  setMessage(message) {
    if (this.message === message) return false;

    this.message = message;
    this.showMessage(message, 0);
  }
}
