import { COLORS } from 'common/consts/colors.js';
import { CARDS } from 'common/consts/types/cards.js';
import { EFFECTS } from 'common/consts/types/effects.js';
import { IMAGES } from 'common/consts/types/images.js';

import { TOOLTIP } from '../consts/tooltip.js';

export const RANDOM_CARDS = {
  [CARDS.INVESTMENT]: {
    type: CARDS.INVESTMENT,
    cost: 3000,
    title: 'Investment',
    image: IMAGES.CARDS.IMAGES.INVESTMENT,
    effects: [EFFECTS.DESTROY, EFFECTS.ADD_ENERGY],
    stats: {
      energy: 3500,
    },
    color: COLORS.CARD.GREEN,
  },

  [CARDS.FORTIFICATE_AROUND_BASE]: {
    type: CARDS.FORTIFICATE_AROUND_BASE,
    cost: 500,
    title: 'Free fortifications',
    image: IMAGES.CARDS.IMAGES.FORTIFICATE_AROUND_BASE,
    effects: [EFFECTS.LIGHTWEIGHT, EFFECTS.FORTIFICATE_AROUND_BASE],
    stats: {
      fortification: 1,
    },
    color: COLORS.CARD.BLUE,
    tooltipTypes: [TOOLTIP.TYPE.LIGHTWEIGHT, TOOLTIP.TYPE.FORTIFICATION],
  },
  [CARDS.PLACE_MINE]: {
    type: CARDS.PLACE_MINE,
    cost: 300,
    title: 'Mine',
    image: IMAGES.CARDS.IMAGES.PLACE_MINE,
    effects: [EFFECTS.LIGHTWEIGHT, EFFECTS.PLACE_MINE],
    color: COLORS.CARD.BLUE,
  },
  [CARDS.PLACE_MINES_RANDOMLY]: {
    type: CARDS.PLACE_MINES_RANDOMLY,
    cost: 500,
    title: 'Random minefield',
    image: IMAGES.CARDS.IMAGES.PLACE_MINES_RANDOMLY,
    effects: [EFFECTS.PLACE_MINES_RANDOMLY],
    color: COLORS.CARD.BLUE,
    stats: {
      numberOfMines: 3,
    },
    tooltipTypes: [TOOLTIP.TYPE.MINE],
  },
  [CARDS.PLACE_RANDOM_CANNON_RANDOMLY]: {
    type: CARDS.PLACE_RANDOM_CANNON_RANDOMLY,
    cost: 1200,
    title: 'Random random cannon',
    image: IMAGES.CARDS.IMAGES.PLACE_RANDOM_CANNON_RANDOMLY,
    effects: [EFFECTS.PLACE_RANDOM_CANNON_RANDOMLY],
    color: COLORS.CARD.RED,
    stats: {
      numberOfCannons: 3,
    },
    tooltipTypes: [TOOLTIP.TYPE.RANDOM_CANNON],
  } /*
  [CARDS.PLACE_WAREHOUSE]: {
    type: CARDS.PLACE_WAREHOUSE,
    cost: 200,
    title: 'Warehouse',
    image: IMAGES.CARDS.IMAGES.PLACE_WAREHOUSE,
    effects: [EFFECTS.PLACE_WAREHOUSE, EFFECTS.DESTROY, EFFECTS.ADD_ENERGY],
    stats: {
      energy: 300,
    },
    color: COLORS.CARD.GREEN,
  },*/,

  [CARDS.SINGLE_FIRE]: {
    type: CARDS.SINGLE_FIRE,
    cost: 100,
    title: 'Single fire',
    image: IMAGES.CARDS.IMAGES.SINGLE_FIRE,
    effects: [EFFECTS.SHOT],
    stats: {
      power: 25,
      piercing: 25,
    },
    color: COLORS.CARD.RED,
    tooltipTypes: [TOOLTIP.TYPE.POWER, TOOLTIP.TYPE.PIERCING],
  },
  /*[CARDS.POISON_TO_ALL]: {
    type: CARDS.POISON_TO_ALL,
    energyCost: 100,
    scienceCost: 100,
    title: 'Poisoning',
    image: IMAGES.CARDS.IMAGES.POISON_TO_ALL,
    description: 'Add POISON poison to all players.',
    effects: [EFFECTS.POISON_TO_ALL],
    stats: {
      poison: 2,
    },
    color: COLORS.CARD.RED,
  },*/
  [CARDS.DESTROY_BUILDING]: {
    type: CARDS.DESTROY_BUILDING,
    cost: 300,
    title: 'Demolition',
    image: IMAGES.CARDS.IMAGES.DESTROY_BUILDING,
    effects: [EFFECTS.DESTROY_OWN_BUILDING, EFFECTS.ADD_ENERGY],
    stats: {
      energy: 300,
    },
    color: COLORS.CARD.GREEN,
  },
  [CARDS.VOID]: {
    type: CARDS.VOID,
    cost: 1500,
    title: 'Void',
    image: IMAGES.CARDS.IMAGES.VOID,
    effects: [EFFECTS.DESTROY, EFFECTS.VOID_TO_OTHERS],
    color: COLORS.CARD.GRAY,
    stats: {
      voidPower: 1,
    },
    tooltipTypes: [TOOLTIP.TYPE.VOID],
  },
  [CARDS.VOID_TO_ALL]: {
    type: CARDS.VOID_TO_ALL,
    cost: 500,
    title: 'Justice till the end',
    image: IMAGES.CARDS.IMAGES.VOID_TO_ALL,
    effects: [EFFECTS.LIGHTWEIGHT, EFFECTS.VOID_TO_ALL],
    color: COLORS.CARD.GRAY,
    stats: {
      voidPower: 1,
    },
    tooltipTypes: [TOOLTIP.TYPE.VOID],
  },
  [CARDS.DESTROY_FIELD]: {
    type: CARDS.DESTROY_FIELD,
    cost: 200,
    title: 'Decay',
    image: IMAGES.CARDS.IMAGES.DESTROY_FIELD,
    effects: [EFFECTS.DRAIN, EFFECTS.DESTROY_WEAKEST_FIELDS],
    color: COLORS.CARD.GRAY,
    stats: {
      drain: 500,
      numberOfFields: 2,
    },
    tooltipTypes: [TOOLTIP.TYPE.DRAIN, TOOLTIP.TYPE.WEAKEST_FIELD],
  },

  [CARDS.REDUCE_DRAIN_RANDOMLY]: {
    type: CARDS.REDUCE_DRAIN_RANDOMLY,
    cost: 200,
    title: 'Discount',
    image: IMAGES.CARDS.IMAGES.REDUCE_DRAIN_RANDOMLY,
    effects: [EFFECTS.DRAIN, EFFECTS.REDUCE_DRAIN_RANDOMLY],
    color: COLORS.CARD.GREEN,
    stats: {
      drain: 800,
      drainReduction: 100,
    },
    tooltipTypes: [TOOLTIP.TYPE.DRAIN],
  },
  [CARDS.PLACE_DEFENDER]: {
    type: CARDS.PLACE_DEFENDER,
    cost: 300,
    title: 'Defender',
    image: IMAGES.CARDS.IMAGES.PLACE_DEFENDER,
    effects: [EFFECTS.DRAIN, EFFECTS.PLACE_DEFENDER],
    color: COLORS.CARD.BLUE,
    stats: {
      drain: 100,
    },
    tooltipTypes: [TOOLTIP.TYPE.DRAIN, TOOLTIP.TYPE.DEFENDER],
  },
  /*
  [CARDS.INCREASE_ENERGY_PRODUCTION]: {
    type: CARDS.INCREASE_ENERGY_PRODUCTION,
    energyCost: 200,
    scienceCost: 10,
    title: 'Safe energy.',
    image: IMAGES.CARDS.IMAGES.INCREASE_ENERGY_PRODUCTION,
    description: 'Increase energy production by ENERGY_PRODUCTION.\nUsage left: USAGE.',
    effects: [EFFECTS.INCREASE_ENERGY_PRODUCTION, EFFECTS.LIMITED_USAGE],
    stats: {
      energyProduction: 20,
      usage: 5,
    },
    color: COLORS.CARD.GREEN,
  },*/
  [CARDS.SPREAD_SHOT_IMPROVING]: {
    type: CARDS.SPREAD_SHOT_IMPROVING,
    cost: 900,
    title: 'Suppressive fire',
    image: IMAGES.CARDS.IMAGES.SPREAD_SHOT_IMPROVING,
    effects: [EFFECTS.SHOT, EFFECTS.INCREASE_SPREAD],
    stats: {
      spread: 10,
      spreadIncrease: 1,
    },
    color: COLORS.CARD.RED,
    tooltipTypes: [TOOLTIP.TYPE.SPREAD],
  },

  [CARDS.DRAW_CARDS]: {
    type: CARDS.DRAW_CARDS,
    cost: 500,
    title: 'Draw cards',
    image: IMAGES.CARDS.IMAGES.DRAW_CARDS,
    effects: [EFFECTS.DRAW_CARDS],
    stats: {
      cards: 2,
    },
    color: COLORS.CARD.GREEN,
  },
  [CARDS.TRASH_CARD]: {
    type: CARDS.TRASH_CARD,
    cost: 500,
    title: 'Slimming',
    image: IMAGES.CARDS.IMAGES.TRASH_CARD,
    effects: [EFFECTS.DESTROY, EFFECTS.TRASH_CARD],
    color: COLORS.CARD.GREEN,
  },
  [CARDS.FAIR_SHOT]: {
    type: CARDS.FAIR_SHOT,
    cost: 400,
    title: 'Fair shot',
    image: IMAGES.CARDS.IMAGES.FAIR_SHOT,
    effects: [EFFECTS.FAIR_SHOT],
    stats: {
      shots: 12,
    },
    color: COLORS.CARD.RED,
    tooltipTypes: [TOOLTIP.TYPE.SNIPER],
  },
  [CARDS.FORTIFICATE_BORDERS]: {
    type: CARDS.FORTIFICATE_BORDERS,
    cost: 1000,
    title: 'Isolation',
    image: IMAGES.CARDS.IMAGES.FORTIFICATE_BORDERS,
    effects: [EFFECTS.FORTIFICATE_BORDERS],
    stats: {
      fortification: 2,
    },
    color: COLORS.CARD.BLUE,
    tooltipTypes: [TOOLTIP.TYPE.FORTIFICATION],
  },
  [CARDS.FORTIFICATE_STRUCTURES]: {
    type: CARDS.FORTIFICATE_STRUCTURES,
    cost: 500,
    title: 'Trenches',
    image: IMAGES.CARDS.IMAGES.FORTIFICATE_STRUCTURES,
    effects: [EFFECTS.FORTIFICATE_STRUCTURES],
    stats: {
      fortification: 2,
    },
    color: COLORS.CARD.BLUE,
    tooltipTypes: [TOOLTIP.TYPE.FORTIFICATION],
  },
  [CARDS.POWER_SHOT]: {
    type: CARDS.POWER_SHOT,
    cost: 300,
    title: 'Bulldozer',
    image: IMAGES.CARDS.IMAGES.POWER_SHOT,
    effects: [EFFECTS.SHOT],
    stats: {
      shots: 1,
      inaccuracy: 0,
      piercing: 2,
      power: 50,
    },
    color: COLORS.CARD.RED,
    tooltipTypes: [TOOLTIP.TYPE.POWER, TOOLTIP.TYPE.PIERCING, TOOLTIP.TYPE.SNIPER],
  },
  [CARDS.ENERGY_TO_BULLETS]: {
    type: CARDS.ENERGY_TO_BULLETS,
    cost: 100,
    title: 'Blast',
    image: IMAGES.CARDS.IMAGES.ENERGY_TO_BULLETS,
    effects: [EFFECTS.ENERGY_TO_BULLETS],
    stats: {
      spread: 25,
    },
    color: COLORS.CARD.RED,
    tooltipTypes: [TOOLTIP.TYPE.SPREAD],
  },

  [CARDS.PLACE_SHORT_RANGE_CANNON]: {
    type: CARDS.PLACE_SHORT_RANGE_CANNON,
    cost: 200,
    title: 'Short-range cannon',
    image: IMAGES.CARDS.IMAGES.PLACE_SHORT_RANGE_CANNON,
    effects: [EFFECTS.PLACE_SHORT_RANGE_CANNON],
    color: COLORS.CARD.BLUE,
    tooltipTypes: [TOOLTIP.TYPE.SHORT_RANGE_CANNON],
  },
  [CARDS.FORTIFICATE_MAIN]: {
    type: CARDS.FORTIFICATE_MAIN,
    cost: 300,
    title: 'Defence packet',
    image: IMAGES.CARDS.IMAGES.FORTIFICATE_MAIN,
    effects: [EFFECTS.DESTROY, EFFECTS.FORTIFICATE_MAIN],
    stats: {
      fortification: 8,
    },
    color: COLORS.CARD.BLUE,
    tooltipTypes: [TOOLTIP.TYPE.FORTIFICATION],
  },
  [CARDS.INCREASE_CARD_LIMIT]: {
    type: CARDS.INCREASE_CARD_LIMIT,
    cost: 1200,
    title: 'Overwhelming',
    image: IMAGES.CARDS.IMAGES.INCREASE_CARD_LIMIT,
    effects: [EFFECTS.DESTROY, EFFECTS.INCREASE_CARD_LIMIT],
    color: COLORS.CARD.GREEN,
  },
  [CARDS.SNIPER_SHOT_IMPROVING]: {
    type: CARDS.SNIPER_SHOT_IMPROVING,
    cost: 900,
    title: 'Slow finisher',
    image: IMAGES.CARDS.IMAGES.SNIPER_SHOT_IMPROVING,
    effects: [EFFECTS.SHOT, EFFECTS.INCREASE_SHOTS],
    stats: {
      shots: 4,
      inaccuracy: 0,
      shotsIncrease: 1,
    },
    color: COLORS.CARD.RED,
    tooltipTypes: [TOOLTIP.TYPE.SNIPER],
  },
  [CARDS.TRASH_AND_COPY_CARD]: {
    type: CARDS.TRASH_AND_COPY_CARD,
    cost: 800,
    title: 'Last breath',
    image: IMAGES.CARDS.IMAGES.TRASH_AND_COPY_CARD,
    effects: [EFFECTS.TRASH_AND_COPY_CARD],
    stats: {
      copies: 3,
    },
    color: COLORS.CARD.GREEN,
    tooltipTypes: [TOOLTIP.TYPE.PERSISTENT, TOOLTIP.TYPE.TEMPORARY],
  },
  [CARDS.ACTIVATE_CANNONS]: {
    type: CARDS.ACTIVATE_CANNONS,
    cost: 1000,
    title: 'Overloading',
    image: IMAGES.CARDS.IMAGES.ACTIVATE_CANNONS,
    effects: [EFFECTS.ACTIVATE_CANNONS],
    stats: {
      times: 2,
    },
    color: COLORS.CARD.RED,
    tooltipTypes: [TOOLTIP.TYPE.CANNONS, TOOLTIP.TYPE.ACTIVATE],
  },
};
