export function joinAdjectives(adjectives) {
  const { length } = adjectives;

  if (length === 1) return adjectives[0];

  const lastIndex = length - 1;
  const secondIndex = length - 2;

  let text = '';

  for (let i = 0; i < length; i++) {
    text += adjectives[i];

    if (i === secondIndex) text += ' and ';
    else if (i === lastIndex) return text;
    else text += ' ,';
  }

  return text;
}

export function pluralize(noun, number) {
  if (number <= 1) return noun;
  return noun + 's';
}
