import { InterfaceElement } from 'client/interface/elements/interface-element.js';

export class Text extends InterfaceElement {
  constructor(params) {
    super(params);

    this.width = params.width || 300;

    this.text = params.text || '';
    this.fontSize = params.fontSize || 20;

    this.height = 0;

    this.createSprites();
    if (params.text) this.measureText();
    this.setVisibility(params.visible || false);
  }

  measureText() {
    this.height = this.sprites.text.height;
  }

  setText(text) {
    this.text = text;
    this.sprites.text.text = text;

    this.measureText();
  }

  createSprites() {
    this.createContainer();
    this.createMainText();
  }

  createMainText() {
    this.sprites.text = this.game.texturesManager.createText(this.text, {
      fontSize: this.fontSize,
      wordWrap: true,
      wordWrapWidth: this.width,
    });

    this.sprites.text.anchor.set(0.5, 0.5);

    this.sprites.container.addChild(this.sprites.text);
  }

  setVisibility(value) {
    this.sprites.container.visible = value;
  }
}
