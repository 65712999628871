const RED1 = 0xff9980;
const BLUE1 = 0x99d6ff;
const GREEN1 = 0xb3ffb3;
const YELLOW1 = 0xffff88;
const GRAY = 0xcccccc;

const RED2 = 0xff483b;
const BLUE2 = 0x3b5fff;
const GREEN2 = 0x3bff55;
const YELLOW2 = 0xf8ff3b;
const PURPLE = 0xb145ff;
const CYAN = 0x45fff3;
const DARK_GRAY = 0x666666;

export const COLORS = {
  CARD: {
    RED: RED1,
    BLUE: BLUE1,
    GREEN: GREEN1,
    GRAY,
  },
  KINGDOM: {
    RED: RED2,
    BLUE: BLUE2,
    GREEN: GREEN2,
    YELLOW: YELLOW2,
    PURPLE: PURPLE,
    CYAN: CYAN,
    DARK_GRAY: DARK_GRAY,
  },
  NAMES: {
    [RED1]: 'red',
    [RED2]: 'red',
    [BLUE1]: 'blue',
    [BLUE2]: 'blue',
    [YELLOW1]: 'yellow',
    [YELLOW2]: 'yellow',
    [GREEN1]: 'green',
    [GREEN2]: 'green',
    [PURPLE]: 'purple',
    [CYAN]: 'cyan',
    [DARK_GRAY]: 'dark gray',
  },
};
