import React from 'react';

import styles from './modal-base.module.css';

export function ModalBase({ onClose, children }) {
  return (
    <React.Fragment>
      <div className={styles.overlay} />
      <div className={styles.container}>
        <div className={styles.modalContainer}>
          <img className={styles.closeButton} src="./images/close-button.png" onClick={onClose} />
          {children}
        </div>
      </div>
    </React.Fragment>
  );
}
