import * as PIXI from 'pixi.js';

import {
  REDRAW_DOT_SIZE,
  REDRAW_ICONS_BOTTOM,
  REDRAW_ICONS_GAP,
  REDRAW_ICONS_HEIGHT,
  REDRAW_ICONS_LEFT,
  REDRAW_ICONS_WIDTH,
} from 'client/consts/layout.js';
import { Z_INDEX } from 'client/consts/z-index.js';

import { IMAGES } from 'common/consts/types/images.js';
import { Point } from 'common/data-types/point.js';

import { InterfaceBase } from '../interface-base.js';

export class RedrawIcons extends InterfaceBase {
  constructor(params) {
    super(params);

    this.kingdom = params.kingdom;

    this.createSprites();
    this.setSpritesPosition();
    this.updateIcons();
  }

  createSprites() {
    this.createSpritesContainer();
    this.createRectSprite();
    this.createDotsSprite();
  }

  createSpritesContainer() {
    this.spritesContainer = new PIXI.Container();
    this.spritesContainer.zIndex = Z_INDEX.REDRAW_ICONS;

    this.registerSprite(this.spritesContainer);
  }

  createRectSprite() {
    this.rectSprite = this.game.texturesManager.createSprite(IMAGES.ICONS.EMPTY_DRAW_RECT);
    this.rectSprite.anchor.set(0.5, 0);
    this.rectSprite.y = 0;

    this.spritesContainer.addChild(this.rectSprite);
  }

  createDotsSprite() {
    const cyclesPerRedraw = this.kingdom.getCyclesPerRedraw();

    let xPosition = -REDRAW_ICONS_WIDTH / 2 + REDRAW_DOT_SIZE / 2;
    const positionAddition = (REDRAW_ICONS_WIDTH - REDRAW_DOT_SIZE) / (cyclesPerRedraw - 2);

    this.drawDots = [];

    for (let i = 0; i < cyclesPerRedraw - 1; i++) {
      const dotSprite = this.game.texturesManager.createSprite(IMAGES.ICONS.EMPTY_DRAW_DOT);
      dotSprite.anchor.set(0.5, 1);
      dotSprite.x = xPosition;
      dotSprite.y = -REDRAW_ICONS_GAP;

      this.drawDots.push(dotSprite);

      this.spritesContainer.addChild(dotSprite);

      xPosition += positionAddition;
    }
  }

  setSpritesPosition() {
    this.position = new Point(
      REDRAW_ICONS_WIDTH / 2 + REDRAW_ICONS_LEFT,
      window.innerHeight - REDRAW_ICONS_HEIGHT / 2 - REDRAW_ICONS_BOTTOM
    );

    this.spritesContainer.x = this.position.x;
    this.spritesContainer.y = this.position.y;
  }

  destroy() {
    super.destroy();
    this.removeSprite(this.spritesContainer);
  }

  afterResize() {
    this.setSpritesPosition();
  }

  onRedrawChange() {
    this.updateIcons();
  }

  lightDots(quantity) {
    const cyclesPerRedraw = this.kingdom.getCyclesPerRedraw();

    for (let i = 0; i < cyclesPerRedraw - 1; i++) {
      this.drawDots[i].texture = this.game.texturesManager.getTexture(
        i < quantity ? IMAGES.ICONS.FULL_DRAW_DOT : IMAGES.ICONS.EMPTY_DRAW_DOT
      );
    }
  }

  lightRect(value) {
    this.rectSprite.texture = this.game.texturesManager.getTexture(value ? IMAGES.ICONS.FULL_DRAW_RECT : IMAGES.ICONS.EMPTY_DRAW_RECT);
  }

  updateIcons() {
    const canRedraw = this.kingdom.getCanRedraw();
    const redrawCounter = this.kingdom.getRedrawCounter();

    this.lightDots(redrawCounter);
    this.lightRect(canRedraw);
  }
}
