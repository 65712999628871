import { SOUNDS } from 'client/consts/sounds.js';
import { InterfaceBase } from 'client/interface/interface-base.js';

import { HARD_CARD_LIMIT } from 'common/consts/gameplay.js';
import { sleep } from 'common/helpers/sleep.js';

import { Active } from './kingdom/active.js';
import { CancelButton } from './kingdom/cancel-button.js';
import { Deck } from './kingdom/deck.js';
import { Discard } from './kingdom/discard.js';
import { FieldSelector } from './kingdom/field-selector.js';
import { Hand } from './kingdom/hand.js';
import { RedrawIcons } from './kingdom/redraw-icons.js';
import { Resources } from './kingdom/resources.js';
import { Target } from './kingdom/target.js';

export class ControlledKingdomInterface extends InterfaceBase {
  constructor(params) {
    super(params);

    this.kingdom = params.kingdom;

    this.focusedOnBoard = false;

    this.isDrawing = false;
    this.isPickingField = false;
    this.isPickingCard = false;
    this.isPlayingCard = false;

    this.createSprites();
    this.pinIntoKingdomCallbacks();
    this.registerEventListeners();
    this.setupCardsSprites();
  }

  createSprites() {
    this.hand = new Hand({ kingdom: this.kingdom, game: this.game, parent: this });
    this.discard = new Discard({ kingdom: this.kingdom, game: this.game, parent: this });
    this.deck = new Deck({ kingdom: this.kingdom, game: this.game, parent: this });
    this.active = new Active({ kingdom: this.kingdom, game: this.game, parent: this });

    this.cancelButton = new CancelButton({ game: this.game, parent: this });
    this.fieldSelector = new FieldSelector({ game: this.game, kingdom: this.kingdom, parent: this });
    this.redrawIcons = new RedrawIcons({ game: this.game, kingdom: this.kingdom });
    this.resources = new Resources({ game: this.game, kingdom: this.kingdom });
    this.target = new Target({ game: this.game, kingdom: this.kingdom, parent: this });
  }

  setupCardsSprites() {
    this.hand.onInitialize();
    this.discard.onInitialize();
    this.deck.onInitialize();
  }

  getInteractive() {
    return !this.getNotInteractive();
  }

  getNotInteractive() {
    return this.isDrawing || this.isPickingTarget || this.isPickingField || this.isPickingCard || this.isPlayingCard;
  }

  setFocusedOnBoard(value) {
    if (value === this.focusedOnBoard) return;

    this.focusedOnBoard = value;

    this.hand.onFocusedOnBoardChange();
    this.active.onFocusedOnBoardChange();
    this.cancelButton.onFocusedOnBoardChange();
  }

  getFocusedOnBoard() {
    return this.focusedOnBoard;
  }

  pinIntoKingdomCallbacks() {
    this.kingdom.onHandChange = this.onHandChange;
    this.kingdom.onDiscardChange = this.onDiscardChange;
    this.kingdom.onDeckChange = this.onDeckChange;
    this.kingdom.onActiveChange = this.onActiveChange;
    this.kingdom.onRedrawChange = this.onRedrawChange;
    this.kingdom.onEnergyChange = this.onEnergyChange;
    this.kingdom.onShufflingStart = this.onShufflingStart;
    this.kingdom.onDrawingStart = this.onDrawingStart;
    this.kingdom.onDrawingCard = this.onDrawingCard;
    this.kingdom.onDrawingEnd = this.onDrawingEnd;
    this.kingdom.onTargetChange = this.onTargetChange;
    this.kingdom.onEliminate = this.onEliminate;
    this.kingdom.onOverdraw = this.onOverdraw;
  }

  registerEventListeners = () => {
    this.game.eventsController.addListener('marketCardClick', this);
  };

  makeInteractive = () => {
    this.deck.unblockInteractive();
    this.hand.unblockInteractive();
    this.target.unblockInteractive();
    this.unblockMarketInteractive();
  };

  blockMarketInteractive = () => {
    this.game.eventsController.runEvent('blockMarketInteractive');
  };

  unblockMarketInteractive = () => {
    this.game.eventsController.runEvent('unblockMarketInteractive');
  };

  onMarketCardClick = ({ card }) => {
    this.game.marketController.tryBuyCard(card, this.kingdom);
  };

  onHandChange = () => {
    this.hand.onChange();
  };

  onDiscardChange = () => {
    this.discard.onChange();
  };

  onDeckChange = () => {
    this.deck.onChange();
  };

  onActiveChange = () => {
    this.active.onChange();
  };

  onEnergyChange = () => {
    this.resources.onEnergyChange();
  };

  onRedrawChange = () => {
    this.deck.onRedrawChange();
    this.redrawIcons.onRedrawChange();
  };

  onShufflingStart = () => {
    this.deck.onShufflingStart();
  };

  onDrawingStart = () => {
    this.isDrawing = true;

    this.deck.blockInteractive();
    this.hand.blockInteractive();
    this.blockMarketInteractive();
  };

  onDrawingCard = () => {
    this.hand.onDrawingCard();
    this.game.soundsController.playSound(SOUNDS.DRAWING, { speedRange: 0.1 });
  };

  onDrawingEnd = () => {
    this.isDrawing = false;

    this.deck.unblockInteractive();
    this.hand.unblockInteractive();
    this.unblockMarketInteractive();
  };

  onCardInHandClick = (card) => {
    if (this.getInteractive()) {
      if (this.game.shouldConfirmWithHost()) {
        this.tryUseCardMultiplayer(card);
      } else {
        this.tryUseCard(card);
      }
    } else if (this.isPickingCard) {
      this.pickedCard = card;
    }
  };

  onDeckClick = () => {
    if (this.game.shouldConfirmWithHost()) {
      this.game.connectionController.sendTryRedraw();
    } else {
      this.kingdom.tryRedraw();
    }
  };

  onTargetChange = () => {
    this.target.updatePosition();
  };

  onEliminate = async () => {
    this.hand.destroy();
    this.discard.destroy();
    this.deck.destroy();
    this.active.destroy();

    this.cancelButton.destroy();
    this.fieldSelector.destroy();
    this.redrawIcons.destroy();
    this.resources.destroy();
    this.target.destroy();

    this.blockMarketInteractive();
  };

  tryUseCard = async (card) => {
    const checkResult = this.game.cardEffectsExecutor.canBePlayed(card, this.kingdom);

    if (!checkResult.can) {
      this.game.interfaceController.infoUnderBar.showMessage(checkResult.message);
      return false;
    }

    this.kingdom.moveCardToActive(card);
    const targets = await this.game.cardEffectsExecutor.pickTargetsForCard(card, this);

    if (targets.destroyed) return false;

    if (targets.cancel) {
      this.kingdom.moveCardBackToHand(card);
      return false;
    }

    card.makeInactive();

    this.isPlayingCard = true;
    this.deck.blockInteractive();
    this.hand.blockInteractive();
    this.target.blockInteractive();
    this.blockMarketInteractive();

    await this.game.cardEffectsExecutor.playCard(card.id, targets, this.kingdom);

    this.isPlayingCard = false;
    this.deck.unblockInteractive();
    this.hand.unblockInteractive();
    this.target.unblockInteractive();
    this.unblockMarketInteractive();

    if (targets.fieldId)
      this.game.eventsController.runEvent('playerPlayCardOnField', {
        field: this.game.mapController.getFieldById(targets.fieldId),
        color: this.kingdom.getColor(),
      });

    card.startDiscardFromPlayTimer();
  };

  tryUseCardMultiplayer = async (card) => {
    const checkResult = this.game.cardEffectsExecutor.canBePlayed(card, this.kingdom);

    if (!checkResult.can) {
      this.game.interfaceController.infoUnderBar.showMessage(checkResult.message);
      return false;
    }

    this.kingdom.moveCardToActive(card);

    // TODO - return ids, not objects - for socket communication
    const targets = await this.game.cardEffectsExecutor.pickTargetsForCard(card, this);

    if (targets.destroyed) return false;

    if (targets.cancel) {
      this.kingdom.moveCardBackToHand(card);
      return false;
    }

    card.makeInactive();
    card.showSpinner();

    this.game.connectionController.sendPlayCard(card.id, targets);
  };

  pickField = async (conditions) => {
    this.pickedField = null;

    this.isPickingField = true;
    this.deck.blockInteractive();
    this.hand.blockInteractive();

    this.setFocusedOnBoard(true);
    this.startListeningToCancel();
    this.game.interfaceController.infoUnderBar.showMessage('Pick a field.', 0);
    this.fieldSelector.pickField(conditions);

    const result = await this.checkForPickedField();
    if (result.destroyed) return result;

    this.setFocusedOnBoard(false);
    this.fieldSelector.hide();
    this.game.interfaceController.infoUnderBar.hideMessage();
    this.stopListeningToCancel();

    this.isPickingField = false;
    this.deck.unblockInteractive();
    this.hand.unblockInteractive();

    return result;
  };

  checkForPickedField = async () => {
    while (true) {
      if (this.destroyed) return { destroyed: true };
      if (this.cancel) return { cancel: true };
      if (this.pickedField) return { fieldId: this.pickedField.id };

      await sleep(10);
    }
  };

  changeTarget(newTarget) {
    if (this.advancedLogicPart) this.kingdom.changeTarget(newTarget);
    if (this.game.shouldConfirmWithHost()) this.game.connectionController.sendChangeTarget(newTarget);
  }

  setPickedField(field) {
    this.pickedField = field;
  }

  pickHandCard = async (conditions = {}) => {
    this.pickedCard = null;

    this.isPickingCard = true;
    this.deck.blockInteractive();
    this.target.blockInteractive();

    this.startListeningToCancel();
    this.game.interfaceController.infoUnderBar.showMessage('Pick a card', 0);
    this.hand.lockCards(conditions);

    const result = await this.checkForPickedCard();
    if (result.destroyed) return result;

    this.hand.unlockCards();
    this.game.interfaceController.infoUnderBar.fadeOutMessage();

    this.isPickingCard = false;
    this.deck.unblockInteractive();
    this.target.unblockInteractive();

    this.stopListeningToCancel();

    return result;
  };

  checkForPickedCard = async () => {
    while (true) {
      if (this.destroyed) return { destroyed: true };
      if (this.cancel) return { cancel: true };
      if (this.pickedCard) return { cardId: this.pickedCard.id };

      await sleep(10);
    }
  };

  startListeningToCancel() {
    this.cancelButton.setVisible(true);
    this.cancel = false;
  }

  stopListeningToCancel() {
    this.cancelButton.setVisible(false);
  }

  onClickCancel() {
    this.cancel = true;
  }

  onOverdraw() {
    this.game.interfaceController.infoUnderBar.showMessage(`You can't have more than ${HARD_CARD_LIMIT} cards in hand.`, 1);
  }
}
