import { EFFECTS } from 'common/consts/types/effects.js';
import { ENTITIES } from 'common/consts/types/entities.js';
import { ERRORS } from 'common/consts/types/errors.js';
import { STRUCTURES } from 'common/consts/types/structures.js';
import { randomElement, randomize } from 'common/helpers/array.js';
import { randomInWithoutRepeat } from 'common/helpers/random.js';

import { addTemporaryToEffects, setShotDefaults } from '../helpers/effects-helpers.js';
import { sleep } from '../helpers/sleep.js';

export class CardEffectsExecutor {
  constructor(game) {
    this.game = game;
  }

  canBePlayed = (card, kingdom) => {
    const cardData = card.getData();
    const { type, effects, stats = {} } = cardData;

    if (card.isUnplayable()) return this.errorReturn('Unplayable', ERRORS.UNPLAYABLE);

    for (let i = 0; i < effects.length; i++) {
      const effect = effects[i];

      switch (effect) {
        case EFFECTS.DRAIN: {
          const { drain } = stats;

          if (drain && kingdom.getEnergy() < drain) {
            return this.errorReturn('Not enought energy!', ERRORS.NOT_ENOUGH_ENERGY);
          }

          break;
        }
        case EFFECTS.MULTISHOT:
        case EFFECTS.ENERGY_TO_BULLETS:
        case EFFECTS.SHOT:
        case EFFECTS.FORTIFICATE_MAIN:
        case EFFECTS.FORTIFICATE_AROUND_BASE: {
          if (!kingdom.getHeadquarter()) return this.errorReturn('You have no main building!');
          break;
        }
        case EFFECTS.PLACE_WAREHOUSE:
        case EFFECTS.PLACE_POWER_STATION:
        case EFFECTS.PLACE_FORGE:
        case EFFECTS.PLACE_SHORT_RANGE_CANNON:
        case EFFECTS.PLACE_DEFENDER:
        case EFFECTS.PLACE_BASIC_CANNON:
        case EFFECTS.PLACE_SNIPER_CANNON:
        case EFFECTS.PLACE_RANDOM_CANNON_RANDOMLY:
        case EFFECTS.FORTIFICATE_AND_PLACE_REINFORCEMENT:
        case EFFECTS.PLACE_MINE: {
          const ownEmptyFields = this.game.mapController.getAllFields().filter((field) => field.ownedBy(kingdom) && field.empty());

          if (ownEmptyFields.length === 0) {
            return this.errorReturn('No empty field on the map!');
          }

          break;
        }
        case EFFECTS.DESTROY_OWN_BUILDING: {
          const ownBuildings = this.game.mapController
            .getAllFields()
            .filter((field) => field.ownedBy(kingdom) && field.structure && field.structure.demolitionable);

          if (ownBuildings.length === 0) {
            return this.errorReturn('No buldings to destroy!');
          }

          break;
        }
        case EFFECTS.FORTIFICATE_OWN_FIELD:
        case EFFECTS.FORTIFICATE_BORDERS: {
          const ownFields = this.game.mapController.getAllFields().filter((field) => field.ownedBy(kingdom));

          if (ownFields.length === 0) {
            return this.errorReturn('No own field on the map!');
          }

          break;
        }
        case EFFECTS.INCREASE_POWER: {
          const cards = kingdom.getHand();
          const possibleTarget = cards.find((targetCard) => targetCard.id !== card.id && targetCard.haveStat('power'));

          if (!possibleTarget) {
            return this.errorReturn('No possible target for power increase!');
          }

          break;
        }
        case EFFECTS.INCREASE_MULTISHOT: {
          const cards = kingdom.getHand();
          const possibleTarget = cards.find((targetCard) => targetCard.id !== card.id && targetCard.haveStat('multishot'));

          if (!possibleTarget) {
            return this.errorReturn('No possible target for multishot increase!');
          }

          break;
        }
        case EFFECTS.TRASH_AND_COPY_CARD: {
          const cards = kingdom.getHand();

          const persistentCards = cards.filter((card) => card.isPersistent());

          if (persistentCards.length <= 1) {
            return this.errorReturn('No cards to pick!');
          }

          break;
        }
        case EFFECTS.REDUCE_DRAIN_RANDOMLY: {
          const cards = kingdom.getDeck();

          const firstTarget = cards.find((searchedCard) => {
            const searchedCardData = searchedCard.getData();
            if (searchedCardData.type === type) return false;
            return searchedCardData.effects.includes(EFFECTS.DRAIN);
          });

          if (!firstTarget) return this.errorReturn('No cards with drain!');
        }
      }
    }

    return this.confirmationReturn();
  };

  errorReturn(message, error) {
    return { can: false, error, message: message };
  }

  confirmationReturn() {
    return { can: true };
  }

  canBePlayedOnTarget(cardId, targets, kingdom) {
    const card = kingdom.searchForPlayableCard(cardId);
    if (!card) return this.errorReturn('Card not in hand!');

    const cardData = card.getData();
    const { effects, costt } = cardData;

    if (costt && kingdom.getEnergy() < costt) {
      return this.errorReturn('Not enought energy!');
    }

    for (let i = 0; i < effects.length; i++) {
      const effect = effects[i];

      switch (effect) {
        case EFFECTS.MULTISHOT:
        case EFFECTS.ENERGY_TO_BULLETS:
        case EFFECTS.SHOT:
        case EFFECTS.FORTIFICATE_MAIN:
        case EFFECTS.FORTIFICATE_AROUND_BASE: {
          if (!kingdom.getHeadquarter()) return this.errorReturn('You have no main building!');
          break;
        }
        case EFFECTS.PLACE_WAREHOUSE:
        case EFFECTS.PLACE_POWER_STATION:
        case EFFECTS.PLACE_FORGE:
        case EFFECTS.PLACE_SHORT_RANGE_CANNON:
        case EFFECTS.PLACE_DEFENDER:
        case EFFECTS.PLACE_BASIC_CANNON:
        case EFFECTS.PLACE_SNIPER_CANNON:
        case EFFECTS.PLACE_MINE: {
          const field = this.game.entitiesController.findById(targets.fieldId);

          if (!field.ownedBy(kingdom)) return this.errorReturn('This field is not yours!');
          if (!field.empty()) return this.errorReturn('This field is not empty!');

          break;
        }
        case EFFECTS.DESTROY_OWN_BUILDING: {
          const field = this.game.entitiesController.findById(targets.fieldId);

          if (!field.structure || !field.structure.isBuilding) return this.errorReturn('No building on the field!');
          if (!field.structure.demolitionable) return this.errorReturn("Can't be destroyed!");

          break;
        }
        case EFFECTS.FORTIFICATE_OWN_FIELD: {
          const field = this.game.entitiesController.findById(targets.fieldId);

          if (!field.ownedBy(kingdom)) return this.errorReturn('This field is not yours!');

          break;
        }
        case EFFECTS.INCREASE_POWER: {
          const targetCard = this.game.entitiesController.findById(targets.cardId);
          if (!targetCard.haveStat('power')) return this.errorReturn('Invalid card');
          if (!targetCard.isCardInHand()) return this.errorReturn('Card not in hand!');

          break;
        }
        case EFFECTS.INCREASE_MULTISHOT: {
          const targetCard = this.game.entitiesController.findById(targets.cardId);
          if (!targetCard.haveStat('multishot')) return this.errorReturn('Invalid card');
          if (!targetCard.isCardInHand()) return this.errorReturn('Card not in hand!');

          break;
        }
      }
    }

    return this.confirmationReturn();
  }

  pickTargetsForCard = (card, kingdom) => {
    const cardData = card.getData();
    const { effects } = cardData;

    for (let i = 0; i < effects.length; i++) {
      const effect = effects[i];

      switch (effect) {
        case EFFECTS.MULTISHOT:
        case EFFECTS.SHOT: {
          return kingdom.pickField();
        }

        case EFFECTS.ENERGY_TO_BULLETS: {
          return kingdom.pickField({ effect: EFFECTS.ENERGY_TO_BULLETS });
        }
        case EFFECTS.PLACE_SNIPER_CANNON:
        case EFFECTS.PLACE_SHORT_RANGE_CANNON:
        case EFFECTS.PLACE_DEFENDER:
        case EFFECTS.PLACE_WAREHOUSE:
        case EFFECTS.PLACE_POWER_STATION:
        case EFFECTS.PLACE_FORGE:
        case EFFECTS.PLACE_MINE:
        case EFFECTS.FORTIFICATE_AND_PLACE_REINFORCEMENT:
        case EFFECTS.PLACE_BASIC_CANNON: {
          return kingdom.pickField({ own: true, empty: true, effectCategory: 'structure-placement' });
        }
        case EFFECTS.DESTROY_OWN_BUILDING: {
          return kingdom.pickField({ own: true, withBuilding: true, withoutHeadquarter: true });
        }
        case EFFECTS.TRASH_CARD: {
          return kingdom.pickHandCard();
        }
        case EFFECTS.TRASH_AND_COPY_CARD: {
          return kingdom.pickHandCard({ persistent: true });
        }
        case EFFECTS.INCREASE_POWER: {
          return kingdom.pickHandCard({ stat: 'power' });
        }
        case EFFECTS.INCREASE_MULTISHOT: {
          return kingdom.pickHandCard({ stat: 'multishot' });
        }
        case EFFECTS.FORTIFICATE_OWN_FIELD: {
          return kingdom.pickField({ own: true, effect: EFFECTS.FORTIFICATE_OWN_FIELD });
        }
      }
    }

    return { cancel: false };
  };

  playCard = async (cardId, targets, kingdom) => {
    const card = kingdom.searchForPlayableCard(cardId);

    if (!card) return false;

    const cardData = card.getData();
    const { type, stats, effects } = cardData;

    let willBeDestroyed = false;

    for (let i = 0; i < effects.length; i++) {
      const effect = effects[i];

      switch (effect) {
        case EFFECTS.DRAIN: {
          const { drain } = stats;

          kingdom.changeEnergy(-drain);

          break;
        }
        case EFFECTS.SHOT: {
          const { spread, power, piercing, inaccuracy, shots } = setShotDefaults(stats);

          const field = this.game.entitiesController.findById(targets.fieldId);
          kingdom.getHeadquarter().fireBulletsTask({ shots, inaccuracy, piercing, power, spread, targets: [field.getPosition()] });

          break;
        }
        case EFFECTS.INCREASE_SPREAD: {
          const { spreadIncrease } = stats;
          card.increaseStat('spread', spreadIncrease);

          break;
        }
        case EFFECTS.INCREASE_SHOTS: {
          const { shotsIncrease } = stats;
          card.increaseStat('shots', shotsIncrease);

          break;
        }

        case EFFECTS.FAIR_SHOT: {
          const { shots } = stats;

          const enemyKingdoms = this.game.kingdomsController.getOtherActiveThan(kingdom);
          const targets = enemyKingdoms.map((kindom) => kindom.getHeadquarter().getField().getPosition());

          kingdom.getHeadquarter().fireBulletsTask({ shots, inaccuracy: 0, targets });

          break;
        }
        case EFFECTS.ENERGY_TO_BULLETS: {
          const { spread } = stats;

          const energy = kingdom.getEnergy();
          kingdom.changeEnergy(-energy);

          const shots = Math.floor(energy / 100);

          const field = this.game.entitiesController.findById(targets.fieldId);
          kingdom.getHeadquarter().fireBulletsTask({ shots, spread, targets: [field.getPosition()] });

          break;
        }
        case EFFECTS.PLACE_BASIC_CANNON: {
          const field = this.game.entitiesController.findById(targets.fieldId);
          this.game.structuresCreator.createStructure(field, STRUCTURES.BASIC_CANNON);

          break;
        }
        case EFFECTS.PLACE_SNIPER_CANNON: {
          const field = this.game.entitiesController.findById(targets.fieldId);
          this.game.structuresCreator.createStructure(field, STRUCTURES.SNIPER_CANNON);

          break;
        }
        case EFFECTS.PLACE_SHORT_RANGE_CANNON: {
          const field = this.game.entitiesController.findById(targets.fieldId);
          this.game.structuresCreator.createStructure(field, STRUCTURES.SHORT_RANGE_CANNON);

          break;
        }
        case EFFECTS.PLACE_DEFENDER: {
          const field = this.game.entitiesController.findById(targets.fieldId);
          this.game.structuresCreator.createStructure(field, STRUCTURES.DEFENDER);

          break;
        }
        case EFFECTS.PLACE_RANDOM_CANNON_RANDOMLY: {
          const { numberOfCannons } = stats;

          const ownEmptyFields = this.game.mapController.getAllFields().filter((field) => field.ownedBy(kingdom) && field.empty());

          for (let i = 0; i < numberOfCannons; i++) {
            const field = randomInWithoutRepeat(ownEmptyFields);

            if (!field) break;

            this.game.structuresCreator.createStructure(field, STRUCTURES.RANDOM_CANNON);
          }

          break;
        }
        case EFFECTS.PLACE_WAREHOUSE: {
          const field = this.game.entitiesController.findById(targets.fieldId);
          this.game.structuresCreator.createStructure(field, STRUCTURES.WAREHOUSE);

          break;
        }
        case EFFECTS.PLACE_FORGE: {
          const field = this.game.entitiesController.findById(targets.fieldId);
          this.game.structuresCreator.createStructure(field, STRUCTURES.FORGE);

          break;
        }
        case EFFECTS.PLACE_POWER_STATION: {
          const field = this.game.entitiesController.findById(targets.fieldId);
          this.game.structuresCreator.createStructure(field, STRUCTURES.POWER_STATION);

          break;
        }
        case EFFECTS.PLACE_MINES_RANDOMLY: {
          const { numberOfMines } = stats;

          const allOwnFields = this.game.mapController.getAllFields().filter((field) => field.ownedBy(kingdom) && field.empty());

          const ownRandomFields = randomize(allOwnFields);

          const max = Math.min(ownRandomFields.length, numberOfMines);

          for (let i = 0; i < max; i++) {
            const field = ownRandomFields[i];
            this.game.structuresCreator.createStructure(field, STRUCTURES.MINE);
          }

          break;
        }
        case EFFECTS.PLACE_MINE: {
          const field = this.game.entitiesController.findById(targets.fieldId);
          this.game.structuresCreator.createStructure(field, STRUCTURES.MINE);

          break;
        }
        case EFFECTS.FORTIFICATE_AND_PLACE_REINFORCEMENT: {
          const { fortification } = stats;

          const field = this.game.entitiesController.findById(targets.fieldId);
          this.game.structuresCreator.createStructure(field, STRUCTURES.REINFORCEMENT);
          field.changeFortification(fortification);

          break;
        }
        case EFFECTS.IMPROVE_HEADQUARTER: {
          const { level } = stats;

          kingdom.getHeadquarter().increaseLevel(level);

          break;
        }
        case EFFECTS.ACTIVATE_CANNONS: {
          const { times } = stats;

          const cannons = this.game.mapController
            .getAllFields()
            .filter((field) => field.ownedBy(kingdom) && !field.empty() && field.structure.isCannon)
            .map((field) => field.structure);

          cannons.forEach((cannon) => {
            for (let i = times; i > 0; i--) {
              cannon.onCycleEnd();
            }
          });

          break;
        }
        case EFFECTS.DESTROY_OWN_BUILDING: {
          const field = this.game.entitiesController.findById(targets.fieldId);
          field.destroyBuilding();

          break;
        }
        case EFFECTS.TRASH_CARD: {
          const targetCard = this.game.entitiesController.findById(targets.cardId);
          targetCard.trash();

          break;
        }
        case EFFECTS.TRASH_AND_COPY_CARD: {
          const { copies } = stats;

          const targetCard = this.game.entitiesController.findById(targets.cardId);

          const newCardData = { effects: [], ...targetCard.getData() };
          addTemporaryToEffects(newCardData.effects);

          for (let i = 0; i < copies; i++) {
            const newCard = this.game.cardsCreator.createCustomCard(newCardData, kingdom, { position: targetCard.getPosition() });
            kingdom.putInDiscard(newCard);
            await sleep(250);
          }

          targetCard.trash();

          break;
        }
        case EFFECTS.MULTISHOT: {
          const { power, multishot } = stats;

          const headquarter = kingdom.getHeadquarter();

          const field = this.game.entitiesController.findById(targets.fieldId);
          headquarter.multishot(multishot, { power, target: field.getPosition() });

          break;
        }
        case EFFECTS.INCREASE_POWER: {
          const targetCard = this.game.entitiesController.findById(targets.cardId);
          targetCard.increaseStat('power', 1);

          break;
        }
        case EFFECTS.INCREASE_MULTISHOT: {
          const targetCard = this.game.entitiesController.findById(targets.cardId);
          targetCard.increaseStat('multishot', 1);

          break;
        }
        case EFFECTS.DRAW_CARDS: {
          const { cards } = stats;

          kingdom.orderDrawingCards(cards);

          break;
        }
        case EFFECTS.ADD_ENERGY: {
          const { energy } = stats;
          kingdom.changeEnergy(energy);

          break;
        }
        case EFFECTS.INCREASE_ENERGY_PRODUCTION: {
          const { energyProduction } = stats;
          kingdom.changeEnergyProduction(energyProduction);

          break;
        }
        case EFFECTS.REDUCE_DRAIN_RANDOMLY: {
          const { drainReduction } = stats;

          const cards = kingdom.getDeck();

          const potentialTargets = cards.filter((searchedCard) => {
            const searchedCardData = searchedCard.getData();
            if (searchedCardData.type === type) return false;
            return searchedCardData.effects.includes(EFFECTS.DRAIN);
          });

          const targetCard = randomElement(potentialTargets);

          targetCard.increaseStat('drain', -drainReduction);
          targetCard.checkForRemovingDrain();

          break;
        }
        case EFFECTS.POISON_TO_ALL: {
          const { poison } = stats;

          this.game.kingdomsController.eachKingdom((kingdom) => {
            kingdom.addEffect({ type: EFFECTS.POISON, power: poison });
          });

          break;
        }
        case EFFECTS.VOID_TO_ALL: {
          const { voidPower } = stats;

          this.game.kingdomsController.eachKingdom((kingdom) => {
            kingdom.addEffect({ type: EFFECTS.VOID, power: voidPower });
          });

          break;
        }
        case EFFECTS.VOID_TO_OTHERS: {
          const { voidPower } = stats;

          const enemyKingdoms = this.game.kingdomsController.getOtherActiveThan(kingdom);
          enemyKingdoms.forEach((kingdom) => {
            kingdom.addEffect({ type: EFFECTS.VOID, power: voidPower });
          });

          break;
        }
        case EFFECTS.RANDOM_FORTIFICATION: {
          const { fortification, fields } = stats;

          const allOwnFields = this.game.mapController.getAllFields().filter((field) => field.ownedBy(kingdom) && field.empty());

          const ownRandomFields = randomize(allOwnFields);

          const max = Math.min(fields, ownRandomFields.length);
          for (let i = 0; i < max; i++) {
            ownRandomFields[i].changeFortification(fortification);
          }

          break;
        }
        case EFFECTS.FORTIFICATE_OWN_FIELD: {
          const { fortification } = stats;

          const field = this.game.entitiesController.findById(targets.fieldId);
          field.changeFortification(fortification);

          break;
        }
        case EFFECTS.FORTIFICATE_BORDERS: {
          const { fortification } = stats;

          const fields = this.game.mapController.getAllFields().filter((field) => field.ownedBy(kingdom) && field.adjacentToEnemy());

          for (let i = 0; i < fields.length; i++) {
            fields[i].changeFortification(fortification);
          }

          break;
        }
        case EFFECTS.FORTIFICATE_MAIN: {
          const { fortification } = stats;

          const headquarter = kingdom.getHeadquarter();
          const mainField = headquarter.getFieldByHex();

          const fields = mainField.allAdjacentFields().filter((field) => field && field.ownedBy(kingdom));
          fields.push(mainField);

          for (let i = 0; i < fields.length; i++) {
            fields[i].changeFortification(fortification);
          }

          break;
        }
        case EFFECTS.FORTIFICATE_AROUND_BASE: {
          const { fortification } = stats;

          const headquarter = kingdom.getHeadquarter();
          const mainField = headquarter.getFieldByHex();

          const fields = mainField.allAdjacentFields().filter((field) => field && field.ownedBy(kingdom) && field.isType(ENTITIES.FIELD));

          for (let i = 0; i < fields.length; i++) {
            fields[i].changeFortification(fortification);
          }

          break;
        }
        case EFFECTS.FORTIFICATE_STRUCTURES: {
          const { fortification } = stats;

          const fields = this.game.mapController
            .getAllFields()
            .filter((field) => field.ownedBy(kingdom) && field.structure && field.structure.isBuilding);

          for (let i = 0; i < fields.length; i++) {
            fields[i].changeFortification(fortification);
          }

          break;
        }
        case EFFECTS.INCREASE_CARD_LIMIT: {
          kingdom.increaseCardsLimit(1);

          break;
        }
        case EFFECTS.DESTROY_WEAKEST_FIELDS: {
          const { numberOfFields } = stats;

          const enemyKingdoms = this.game.kingdomsController.getOtherActiveThan(kingdom);
          enemyKingdoms.forEach((kingdom) => {
            kingdom.voidWeakestFields(numberOfFields);
          });

          break;
        }
        case EFFECTS.DESTROY: {
          willBeDestroyed = true;

          break;
        }
        case EFFECTS.TEMPORARY: {
          willBeDestroyed = true;

          break;
        }
        case EFFECTS.LIMITED_USAGE: {
          const { usage } = stats;

          if (usage <= 1) {
            willBeDestroyed = true;
          } else {
            card.increaseStat('usage', -1);
          }

          break;
        }
        case EFFECTS.REFILL: {
          kingdom.orderDrawingCards(1);

          break;
        }
      }
    }

    if (willBeDestroyed) {
      card.trash();
    }
  };

  cancelCard(kingdom, card) {
    const cardData = card.getData();
    const { energyCost } = cardData;

    kingdom.active.removeCard(card);
    kingdom.hand.addCard(card);

    if (energyCost) {
      kingdom.changeEnergy(energyCost);
    }
  }
}
