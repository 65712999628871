import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { BackButton } from 'components/common/back-button';
import { Button } from 'components/common/button';
import { CentredContainer } from 'components/common/centred-container';
import { TextInput } from 'components/common/text-input';

import { getStatistics, setDevToken } from 'store/main/slice';

import styles from './styles.module.css';

export function StatisticsView({ viewParams }) {
  const dispatch = useDispatch();

  const devToken = useSelector((state) => state.main.devToken);

  const [token, setToken] = useState(devToken);
  const [statistics, setStatistics] = useState(null);

  const onClickSave = () => {
    dispatch(setDevToken({ devToken: token }));
  };

  const onClickGet = () => {
    getStatistics({
      token,
      callback: (response) => {
        const { errorMessage, statistics } = response;

        if (errorMessage) return alert(errorMessage);
        setStatistics(statistics);
      },
    });
  };

  return (
    <CentredContainer>
      <p className={styles.title}>Statistics</p>

      <div className={`${styles.section} ${styles.settingsSection}`}>
        <div className={styles.inputWrapper}>
          <label className={styles.label}>Token:</label>
          <TextInput placeholder="token" value={token} onChange={(text) => setToken(text)} />
          <Button onClick={onClickSave}>Save</Button>
          <Button onClick={onClickGet}>Get Stats</Button>
        </div>
      </div>

      {statistics && (
        <div className={styles.statsGrid}>
          <div className={styles.statLabel}>Players count</div>
          <div className={styles.statValue}>{statistics.playersCount}</div>

          <div className={styles.statLabel}>Start time</div>
          <div className={styles.statValue}>{statistics.startTime}</div>

          <div className={styles.statLabel}>Players nicks</div>
          <div className={styles.statValue}>{statistics.playersNicks.join(', ')}</div>
        </div>
      )}

      <div className={styles.buttonWrapper}>
        <BackButton />
      </div>
    </CentredContainer>
  );
}
