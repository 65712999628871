import { PreparationRow } from './preparation-row';

import styles from './styles.module.css';

export function PreparationsList({ preparations }) {
  if (!preparations) return null;

  return (
    <div className={styles.container}>
      {preparations.map((preparation) => (
        <PreparationRow key={preparation.id} preparation={preparation} />
      ))}
    </div>
  );
}
