export class Sequencer {
  constructor(params = {}) {
    this.value = params.start || 1;

    this.value -= 1;
  }

  next() {
    this.value += 1;
    return this.value;
  }
}
