import { BackButton } from '../back-button';
import { useState } from 'react';

import { Button } from 'components/common/button';
import { Checkbox } from 'components/common/checkbox';
import { TextInput } from 'components/common/text-input';

import styles from './styles.module.css';

export function PreparationForm({ params, onSubmit, buttonLabel, secondButton }) {
  const [name, setName] = useState(params.name);
  const [cycleTime, setCycleTime] = useState(params.cycleTime);
  const [mapSize, setMapSize] = useState(params.mapSize);
  const [isPrivate, setIsPrivate] = useState(params.isPrivate);

  const onCreateClick = () => {
    onSubmit({ name, cycleTime, mapSize, isPrivate });
  };

  return (
    <div className={styles.container}>
      <div className={styles.inputsContainer}>
        <div className={styles.section}>
          <div className={styles.label}>Name:</div>
        </div>
        <div className={styles.section}>
          <TextInput placeholder="Name" value={name} onChange={(text) => setName(text)} />
        </div>
        <div className={styles.section}>
          <div className={styles.label}>Time per cycle (seconds):</div>
        </div>
        <div className={styles.section}>
          <TextInput placeholder="5" value={cycleTime} onChange={(text) => setCycleTime(parseInt(text, 10))} />
        </div>
        {/*<div className={styles.section}>
          <div className={styles.label}>Map size (in hexes):</div>
        </div>
        <div className={styles.section}>
          <TextInput placeholder="31" value={mapSize} onChange={(text) => setMapSize(parseInt(text, 10))} />
        </div>
        <div className={styles.section}>
          <div className={styles.label}>Private game:</div>
        </div>
        <div className={styles.checkboxSection}>
          <Checkbox value={isPrivate} onChange={(value) => setIsPrivate(value)} />
        </div>*/}
      </div>

      <div className={styles.buttonsSection}>
        <Button onClick={onCreateClick}>{buttonLabel || 'Save'}</Button>
        {secondButton}
      </div>
    </div>
  );
}
