import styles from './styles.module.css';

export function PreparationParams({ preparation }) {
  if (!preparation) return null;

  const { name, cycleTime, mapSize, isPrivate } = preparation;

  return (
    <div className={styles.container}>
      <div className={styles.headSection}>
        <div className={styles.name}>{name}</div>
      </div>
      <div className={styles.detailSection}>
        <div className={styles.detailLabel}>Time for cycle:</div>
        <div className={styles.detailValue}>{cycleTime} s</div>
      </div>
      <div className={styles.detailSection}>
        <div className={styles.detailLabel}>Map size:</div>
        <div className={styles.detailValue}>{mapSize} hexes</div>
      </div>

      <div className={styles.detailSection}>
        <div className={styles.singleDetail}>{isPrivate ? 'Private' : 'Public'}</div>
      </div>
    </div>
  );
}
