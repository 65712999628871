import { Container } from 'pixi.js';

import { Z_INDEX } from 'client/consts/z-index.js';

import { Point } from 'common/data-types/point.js';
import { EntityBase } from 'common/entities/entity-base.js';

export class BaseParticle extends EntityBase {
  constructor(params) {
    super(params);

    this.sprites = {};
  }

  initializator(params) {
    const { offset, position } = params;

    this.movement = Point.fromAngle(this.angle, this.speed);

    this.position = position;
    this.offsetPosition(offset);

    this.setupSprites();
  }

  offsetPosition(offset) {
    if (!offset) return;
    this.position.add(this.movement.clone().setDistance(offset));
  }

  setupSprites() {
    this.createContainer();
    this.createSprites();
    this.setContainerPosition();
  }

  createContainer() {
    this.sprites.container = new Container();
    this.sprites.container.zIndex = this.zIndex || 1;
    this.sprites.container.sortableChildren = true;

    this.registerMapSprite(this.sprites.container);
  }

  createSprites() {
    if (this.imageType) this.createStandardImage();
    this.createSprite();
  }

  createSprite() {}

  createStandardImage() {
    this.sprites.standardImage = this.game.texturesManager.createStandardSprite(this.imageType);
    this.sprites.standardImage.angle = this.imageAngle;
    this.sprites.standardImage.zIndex = Z_INDEX.PARTICLES;

    if (this.color) this.sprites.standardImage.tint = this.color;

    this.sprites.container.addChild(this.sprites.standardImage);
  }

  setContainerPosition() {
    this.sprites.container.x = this.position.x;
    this.sprites.container.y = this.position.y;
  }

  activate(maxDelta) {
    const delta = this.calculateDelta(maxDelta);

    this.calculateLifetimeTimer(maxDelta);
    this.calculatePosition(delta);
  }

  calculateDelta(delta) {
    if (this.lifetime !== null) {
      if (this.lifetime < delta) return this.lifetime;
    }

    return delta;
  }

  calculateLifetimeTimer(delta) {
    this.lifetimeTimer -= delta;
    if (this.lifetimeTimer <= 0) this.destroy();
  }

  calculatePosition(delta) {
    this.position.addScaled(this.movement, delta);
    this.setContainerPosition();
  }

  destroy() {
    super.destroy();
    this.removeMapSprite(this.sprites.container);
  }
}
