export const Z_INDEX = {
  BACKGROUND: 1,
  MAP: 2,
  PARTICLES: 3,
  MAP_INTERFACE: 4,
  TOOLTIP: 400,
  TOOLTIP_BOXES: 500,
  SELECTION_OVERLAY: 300,
  CANCEL_OVERLAY: 900,
  CARDS_BOARD: 90,
  CHEAT_MENU: 100,
  CYCLE_BAR: 5,
  MARKET_BUTTONS: 150,
  CANCEL_BUTTON: 350,
  REDRAW_ICONS: 5,
  RESOURCES: 95,
  SCIENCE_FOR_MARKET: 100,
  EFFECT: 5,
  MESSAGE_BOX: 600,
  DECK: {
    CARDS: 6,
    BOTTOM: 5,
    TOP: 7,
  },
  DISCARD: 8,
  ACTIVE: 15,
  HAND: 10,
  INFO_TEXT: 90,

  MAP_SCORLLING_ACTIVE: 400,
  MAP_SCORLLING_INACTIVE: 3,

  FOCUS_AREA: 500,

  // nested

  FIELD_SELECTOR: 4,
  TARGET: 2,
  FORTIFICATION: 3,
};
