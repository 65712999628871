import { InterfaceBase } from '../interface-base.js';

export class CentredInterfaceGroup extends InterfaceBase {
  constructor(params) {
    super(params);

    this.elements = {};
    this.layoutElements = [];
    this.centredElements = [];

    this.width = params.width || 0;

    this.gap = params.gap || 0;
    this.padding = params.padding || 0;

    this.visible = false;
  }

  registerElement(name, element) {
    this.elements[name] = element;

    if (element.centredPositioning) {
      this.centredElements.push(element);
    } else if (!element.ignoreAutopositioning) {
      this.layoutElements.push(element);
    }
  }

  destroyElements() {
    this.forEachElement((element, key) => {
      element.destroy();
      delete this.elements[key];
    });

    this.layoutElements = [];
    this.centredElements = [];
  }

  setVisibility(value) {
    this.visible = value;
    this.forEachElement((element) => element.setVisibility(value));
  }

  isVisible() {
    return this.visible;
  }

  forEachElement(callback) {
    for (const [key, element] of Object.entries(this.elements)) {
      callback(element, key);
    }
  }

  detectWidth() {
    const elementsSize = this.layoutElements.reduce((a, element) => Math.max(a, element.width), 0);
    const paddings = this.padding * 2;

    this.width = elementsSize + paddings;
  }

  detectHeight() {
    const elementsSize = this.layoutElements.reduce((a, element) => a + element.verticalSize(), 0);
    const gaps = this.gap * (this.layoutElements.length - 1);
    const paddings = this.padding * 2;

    this.height = elementsSize + gaps + paddings;
  }

  setElementsPositions() {
    this.detectHeight();

    let currentY = window.innerHeight / 2 - this.height / 2 + this.padding;

    const max = this.layoutElements.length;

    for (let i = 0; i < max; i++) {
      const element = this.layoutElements[i];

      if (element.marginTop) currentY += element.marginTop;

      element.setPosition(window.innerWidth / 2, currentY, { addHalfHeight: true });
      currentY += element.height + this.gap;
    }

    this.centredElements.forEach((element) => {
      element.setPosition(window.innerWidth / 2, window.innerHeight / 2);
    });
  }

  destroy() {
    super.destroy();
    this.destroyElements();
  }
}
