import { HEXAGON_SIZE } from 'client/consts/layout.js';

import { SHORT_RANGE_CANNON_RANGE } from 'common/consts/structures.js';
import { IMAGES } from 'common/consts/types/images.js';
import { STRUCTURES } from 'common/consts/types/structures.js';

import { BaseCannon } from './base.js';

export class ShortRangeCannon extends BaseCannon {
  constructor(params) {
    super(params);

    this.range = HEXAGON_SIZE * SHORT_RANGE_CANNON_RANGE;

    this.type = STRUCTURES.SHORT_RANGE_CANNON;
  }

  getSpriteName() {
    return IMAGES.STRUCTURES.SHORT_RANGE_CANNON;
  }
}
