import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Config from 'helpers/config';

import { Button } from 'components/common/button';
import { CentredContainer } from 'components/common/centred-container';
import { DiscordIcon } from 'components/common/discord-icon';
import { ModalBase } from 'components/common/modal-base';
import { ResumeMatch } from 'components/common/resume-match';

import { openView } from 'store/temporary/slice';

import styles from './styles.module.css';

const SERVER_ADDRESS = 'https://discord.gg/eNEGTeuNuN';

export function HomeView() {
  const dispatch = useDispatch();

  const isConnected = useSelector((state) => state.websocket.isConnected);
  const devMode = useSelector((state) => state.main.devMode);

  const [isModalVisible, setIsModalVisible] = useState(false);

  const onSoloPlayClick = () => {
    dispatch(openView({ viewName: 'singleplayer-selection' }));
  };

  const onMultiPlayClick = () => {
    dispatch(openView({ viewName: 'multiplayer-type' }));
  };

  const onStatsClick = () => {
    dispatch(openView({ viewName: 'statistics' }));
  };

  return (
    <CentredContainer>
      <ResumeMatch />

      <img className={styles.title} src="./images/game-title.png" alt="Hex Builder game title" />

      <div className={styles.buttonGroupWrapper}>
        <Button onClick={onSoloPlayClick}>Play</Button>
      </div>

      {/*Config.multiplayerEnabled && (
        <div className={styles.buttonGroupWrapper}>
          <Button onClick={onMultiPlayClick} disabled={!isConnected}>
            Play alpha multiplayer
          </Button>
        </div>
      )}

      {devMode && (
        <div className={styles.buttonGroupWrapper}>
          <Button onClick={onStatsClick}>Statistics</Button>
        </div>
      )*/}

      <DiscordIcon onClick={() => setIsModalVisible(true)} />

      {isModalVisible && (
        <ModalBase onClose={() => setIsModalVisible(false)}>
          <div className={styles.modalContent}>
            <div className={styles.modalTitle}>Discord</div>
            <div className={styles.modalDescription}>Join our discord server to get the latest news and updates about the game.</div>
            <div className={styles.modalButtonWrapper}>
              <Button
                onClick={() => {
                  navigator.clipboard.writeText(SERVER_ADDRESS);
                  setIsModalVisible(false);
                }}
              >
                Copy
              </Button>
              <Button
                onClick={() => {
                  window.open(SERVER_ADDRESS, '_blank');
                  setIsModalVisible(false);
                }}
              >
                Open
              </Button>
            </div>
          </div>
        </ModalBase>
      )}
    </CentredContainer>
  );
}
