import * as PIXI from 'pixi.js';

import { InterfaceElement } from 'client/interface/elements/interface-element.js';

export class Overlay extends InterfaceElement {
  constructor(params) {
    super(params);

    this.opacity = params.opacity || 0.6;

    this.createSprites();
    this.setVisibility(params.visible || false);

    this.ignoreAutopositioning = true;
  }

  createSprites() {
    this.createContainer();
    this.createBackground();
  }

  createBackground() {
    this.sprites.background = new PIXI.Graphics();
    this.sprites.background.eventMode = 'static';

    this.updateBackground();

    this.sprites.container.addChild(this.sprites.background);
  }

  updateBackground() {
    this.sprites.background.clear().rect(0, 0, window.innerWidth, window.innerHeight).fill({ color: 0x000000, alpha: this.opacity });
  }

  afterResize() {
    this.updateBackground();
  }
}
