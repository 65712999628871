import { CentredContainer } from 'components/common/centred-container';

import styles from './about.module.css';

export function AboutView() {
  return (
    <CentredContainer>
      <p>About</p>
    </CentredContainer>
  );
}
