import { ALL_CARDS } from 'common/cards-data/all.js';
import { Card } from 'common/entities/card.js';
import { cloneObject } from 'common/helpers/data.js';

import { EFFECT_DESCRIPTIONS } from '../consts/effect-descriptions.js';

export class CardsCreator {
  constructor(game) {
    this.game = game;
  }

  createCard(params) {
    const { type, id } = params;

    return new Card({
      data: cloneObject(ALL_CARDS[type]),
      game: this.game,
      id,
      ...params,
    });
  }

  fromSocketData(socketData, owner) {
    const { id, type, stats, energyCost } = socketData;

    const data = cloneObject(ALL_CARDS[type]);

    if (typeof energyCost !== 'undefined') data.energyCost = energyCost;

    data.stats = { ...data.stats, ...stats };

    return new Card({
      id,
      data,
      owner,
      game: this.game,
    });
  }

  createCustomCard(cardData, kingdom, params) {
    const newCard = new Card({
      data: cardData,
      game: this.game,
      owner: kingdom,
      ...params,
    });

    newCard.showFront();

    return newCard;
  }

  createCopyOfCard(prototypeCard, kingdom, params) {
    const cardData = prototypeCard.getData();
    const { type } = cardData;

    const newCard = new Card({
      data: cloneObject(ALL_CARDS[type]),
      game: this.game,
      owner: kingdom,
      position: prototypeCard.getPosition(),
      ...params,
    });

    newCard.showFront();

    return newCard;
  }

  generateDescription(cardData) {
    const { effects, stats } = cardData;

    let description = '';

    if (effects) {
      const max = effects.length;
      for (let i = 0; i < max; i++) {
        const effect = effects[i];
        description += this.getEffectDescription(effect, stats) + '\n';
      }
    }

    return this.fillTextWithStats(description, stats);
  }

  getEffectDescription(effectType, stats) {
    const entry = EFFECT_DESCRIPTIONS[effectType];

    if (typeof entry === 'function') return entry(stats);

    if (typeof entry === 'string') return entry;

    return 'MISSING DESCRIPTION';
  }

  fillTextWithStats(rawText, stats) {
    if (!stats) return rawText;

    let text = `${rawText}`;

    if (stats.energy) {
      text = text.replaceAll('ENERGY', stats.energy / 100);
    }

    if (stats.drainReduction) {
      text = text.replaceAll('DRAIN_REDUCTION', stats.drainReduction / 100);
    }

    if (stats.drain) {
      text = text.replaceAll('DRAIN', stats.drain / 100);
    }

    if (stats.energyProduction) {
      text = text.replaceAll('ENERGY_PRODUCTION', stats.energyProduction / 100);
    }

    for (let key in stats) {
      const formatedKey = key.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`).toUpperCase();
      const regexp = `(\\W)(${formatedKey})(\\W)`;
      text = text.replaceAll(new RegExp(regexp, 'g'), `$1${stats[key]}$3`);
    }

    return text;
  }
}
