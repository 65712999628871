import { Z_INDEX } from 'client/consts/z-index.js';

import { LEFT_MOUSE_BUTTON } from 'common/consts/control.js';
import { Point } from 'common/data-types/point.js';

import { FixedCardsButton } from './buttons/fixed-cards.js';
import { RandomCardsButton } from './buttons/random-cards.js';
import { InterfaceBase } from './interface-base.js';

export const MARKET_BUTTON_HEIGHT = 58;
export const MARKET_BUTTON_WIDTH = 50;

export class ActionButtons extends InterfaceBase {
  constructor(params = {}) {
    super(params);

    this.zIndex = Z_INDEX.MARKET_BUTTONS;

    this.createSprites();

    this.updatePosition();
    this.updateButtonsPositions();
  }

  createSprites() {
    this.createContainer();
    this.registerSprite(this.sprites.container);

    this.fixedCardsButton = new FixedCardsButton({ parentContainer: this.sprites.container, game: this.game });
    this.randomCardsButton = new RandomCardsButton({ parentContainer: this.sprites.container, game: this.game });
  }

  updatePosition() {
    this.position = new Point(window.innerWidth, window.innerHeight / 2);

    this.sprites.container.x = this.position.x;
    this.sprites.container.y = this.position.y;
  }

  updateButtonsPositions() {
    let xOffset = -this.fixedCardsButton.getWidth() / 2;
    let yOffset = -this.fixedCardsButton.getHeight() / 2;

    this.fixedCardsButton.setPosition(xOffset, yOffset);

    xOffset -= this.fixedCardsButton.getWidth() / 2;
    yOffset += this.fixedCardsButton.getHeight() * 0.75;

    this.randomCardsButton.setPosition(xOffset, yOffset);
  }

  afterResize() {
    this.updatePosition();
    this.updateButtonsPositions();
  }

  destroy() {
    super.destroy();
    this.removeMapSprite(this.sprites.container);
  }
}
