import { HEXAGON_RADIUS } from '../../consts/layout.js';
import { Graphics } from 'pixi.js';

import { Z_INDEX } from 'client/consts/z-index.js';

import { perFrameSpeed, perFrameTimer } from 'common/helpers/converters.js';
import { random } from 'common/helpers/random.js';

import { BaseParticle } from './base.js';

const HEIGHT = 6;
const WIDTH = 30;
const BORDER_RADIUS = 2;

export class Sprinkle extends BaseParticle {
  constructor(params) {
    super(params);

    this.lifetime = perFrameTimer(0.5);
    this.lifetimeTimer = this.lifetime;

    this.speed = perFrameSpeed(HEXAGON_RADIUS);
    this.angle = random(0, 360);
    this.imageAngle = this.angle + 90;

    this.color = params.color;

    this.initializator(params);
  }

  createSprite() {
    this.sprites.rect = new Graphics().roundRect(-WIDTH / 2, -HEIGHT / 2, WIDTH, HEIGHT, BORDER_RADIUS).fill(this.color);
    this.sprites.rect.angle = this.imageAngle;
    this.sprites.rect.zIndex = Z_INDEX.PARTICLES;

    this.sprites.container.addChild(this.sprites.rect);
  }
}
