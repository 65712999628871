import { BASE_CANNONS_INACCURACY } from 'common/consts/structures.js';
import { EFFECTS } from 'common/consts/types/effects.js';

export function setShotDefaults(stats) {
  const { spread = 1, power = 1, piercing = 1, inaccuracy = BASE_CANNONS_INACCURACY, shots = 1 } = stats;
  return { ...stats, spread, power, piercing, inaccuracy, shots };
}

const TEMPORARY_BANLIST = [EFFECTS.DESTROY, EFFECTS.TEMPORARY, EFFECTS.LIMITED_USAGE];

export function addTemporaryToEffects(effects) {
  effects.filter((effect) => !TEMPORARY_BANLIST.includes(effect));
  effects.unshift(EFFECTS.TEMPORARY);
  return effects;
}
