import { PreparationForm } from '../../components/common/preparation-form';
import { useDispatch, useSelector } from 'react-redux';

import { BackButton } from 'components/common/back-button';
import { CentredContainer } from 'components/common/centred-container';
import { Title } from 'components/common/title';

import { openView } from 'store/temporary/slice';
import { createPreparation } from 'store/websocket/slice';

import styles from './styles.module.css';

export function PreparationCreatorView({ viewParams }) {
  const dispatch = useDispatch();

  const { nick } = useSelector((state) => state.main.player);

  const onSubmit = (preparationParams) => {
    dispatch(createPreparation({ params: preparationParams, onSuccess: onCreateSuccess, onFailure: onCreateFailure }));
  };

  const onCreateSuccess = (response) => {
    dispatch(openView({ viewName: 'preparation-launching', viewParams: { preparationId: response.preparation.id } }));
  };

  const onCreateFailure = (response) => {
    dispatch(openView({ viewName: 'announcement', viewParams: { title: "Can't create a new game", message: response.errorMessage } }));
  };

  return (
    <CentredContainer>
      <Title>Create a new match</Title>

      <PreparationForm
        params={{
          name: `${nick}'s Game`,
          cycleTime: 5,
          mapSize: 31,
          isPrivate: false,
        }}
        onSubmit={onSubmit}
        buttonLabel="Create"
        secondButton={<BackButton />}
      />
    </CentredContainer>
  );
}
