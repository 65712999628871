import { uniqueString } from 'common/helpers/data.js';

export const IMAGES = {
  INCORRECT_FIELD_SELECTION: uniqueString(),

  SPINNER: uniqueString(),
  ICONS: {
    ENERGY: uniqueString(),
    HEXAGON: uniqueString(),
    FORTIFICATION: uniqueString(),

    EMPTY_DRAW_DOT: uniqueString(),
    FULL_DRAW_DOT: uniqueString(),
    EMPTY_DRAW_RECT: uniqueString(),
    FULL_DRAW_RECT: uniqueString(),

    POISON: uniqueString(),
    VOID: uniqueString(),
  },
  STRUCTURES: {
    BASIC_CANNON: uniqueString(),
    HEADQUARTER: uniqueString(),
    RANDOM_CANNON: uniqueString(),
    SHORT_RANGE_CANNON: uniqueString(),
    SNIPER_CANNON: uniqueString(),
    DEFENDER: uniqueString(),
    WAREHOUSE: uniqueString(),
    POWER_STATION: uniqueString(),
    FORGE: uniqueString(),
    REINFORCEMENT: uniqueString(),
    MINE: uniqueString(),
  },
  ENTITIES: {
    BULLET: uniqueString(),
    EMPTY_DECK: uniqueString(),
    TARGET: uniqueString(),
    FIELD_SELECTOR: uniqueString(),
    FIELD: uniqueString(),
    SMALL_FIELD: uniqueString(),
    WALL: uniqueString(),
    BACKGROUND_TILE: uniqueString(),
  },
  PARTICLES: {
    BUILDING_AURA: uniqueString(),
    HEXAGONAL: uniqueString(),
  },
  CARDS: {
    SPINNER: uniqueString(),
    BACKGROUND: uniqueString(),
    IMAGE_BACKGROUND: uniqueString(),
    BACK: uniqueString(),
    OVERLAY: uniqueString(),
    SELECTION: uniqueString(),
    IMAGES: {
      PLACE_BASIC_CANNON: uniqueString(),
      PLACE_SHORT_RANGE_CANNON: uniqueString(),
      PLACE_RANDOM_CANNON_RANDOMLY: uniqueString(),
      PLACE_SNIPER_CANNON: uniqueString(),
      PLACE_DEFENDER: uniqueString(),

      PLACE_WAREHOUSE: uniqueString(),
      PLACE_POWER_STATION: uniqueString(),
      PLACE_FORGE: uniqueString(),

      PLACE_MINE: uniqueString(),
      PLACE_MINES_RANDOMLY: uniqueString(),

      IMPROVE_HEADQUARTER: uniqueString(),
      ACTIVATE_CANNONS: uniqueString(),

      DESTROY_BUILDING: uniqueString(),

      ADD_ENERGY: uniqueString(),
      SPARK: uniqueString(),
      INCREASE_ENERGY_PRODUCTION: uniqueString(),
      REDUCE_DRAIN_RANDOMLY: uniqueString(),
      INVESTMENT: uniqueString(),

      TRASH_CARD: uniqueString(),
      TRASH_AND_COPY_CARD: uniqueString(),

      DRAW_CARDS: uniqueString(),

      INCREASE_POWER: uniqueString(),
      INCREASE_MULTISHOT: uniqueString(),

      SPREAD_SHOT: uniqueString(),
      SPREAD_SHOT_IMPROVING: uniqueString(),
      SINGLE_FIRE: uniqueString(),
      SNIPER_FIRE: uniqueString(),
      SNIPER_SHOT_IMPROVING: uniqueString(),
      MULTISHOT: uniqueString(),
      ENERGY_TO_BULLETS: uniqueString(),
      FAIR_SHOT: uniqueString(),
      POWER_SHOT: uniqueString(),

      POISON_TO_ALL: uniqueString(),
      VOID: uniqueString(),
      VOID_TO_ALL: uniqueString(),
      DESTROY_FIELD: uniqueString(),

      RANDOM_FORTIFICATION: uniqueString(),
      FORTIFICATE_OWN_FIELD: uniqueString(),
      FORTIFICATE_BORDERS: uniqueString(),
      FORTIFICATE_MAIN: uniqueString(),
      FORTIFICATE_AROUND_BASE: uniqueString(),
      FORTIFICATE_STRUCTURES: uniqueString(),
      FORTIFICATE_AND_PLACE_REINFORCEMENT: uniqueString(),

      INCREASE_CARD_LIMIT: uniqueString(),

      JUNK: uniqueString(),
    },
  },
  INTERFACE: {
    MARKET_BUTTON: uniqueString(),
    CLOSE_BUTTON: uniqueString(),
    SCROLLBAR: {
      TOP_BUTTON: uniqueString(),
      BOTTOM_BUTTON: uniqueString(),
      THUMB_HANDLER: uniqueString(),
      THUMB_TOP: uniqueString(),
      THUMB_BOTTOM: uniqueString(),
      THUMB_CENTER: uniqueString(),
      TRACK: uniqueString(),
    },
    SLIDER: {
      EDGE: uniqueString(),
      HANDLE: uniqueString(),
      FILLING: uniqueString(),
    },
    BUTTON: uniqueString(),
    BUTTON_HOVERED: uniqueString(),
  },
};
