import styles from './text-input.module.css';

export function TextInput(props) {
  return (
    <input
      className={styles.textInput}
      type="text"
      {...props}
      onChange={(event) => props.onChange && props.onChange(event.target.value)}
    />
  );
}
