import styles from './styles.module.css';

export function PlayerDetails({ player, ownPlayerId, hostId }) {
  const { nick, id } = player;

  const isYou = id === ownPlayerId;
  const isHost = id === hostId;

  return (
    <div className={styles.nick}>
      - {nick || 'Unknown'} {isYou && '(You)'} {isHost && '(Owner)'}
    </div>
  );
}
