import { DISCARD_BOTTOM, DISCARD_HEIGHT, DISCARD_WIDTH, DISCART_RIGHT } from 'client/consts/layout.js';
import { Z_INDEX } from 'client/consts/z-index.js';

import { Point } from 'common/data-types/point.js';

import { InterfaceBase } from '../interface-base.js';

export class Discard extends InterfaceBase {
  constructor(params) {
    super(params);

    this.kingdom = params.kingdom;
    this.parent = params.parent;

    this.updatePosition();
  }

  updatePosition() {
    this.position = new Point(
      window.innerWidth - DISCARD_WIDTH / 2 - DISCART_RIGHT,
      window.innerHeight - DISCARD_HEIGHT / 2 - DISCARD_BOTTOM
    );
  }

  onInitialize() {
    const cards = this.kingdom.getDiscard();

    this.updateCardsInterfaces(cards);
    this.recalculateCardsPositions(cards, true);
  }

  onChange() {
    const cards = this.kingdom.getDiscard();

    this.updateCardsInterfaces(cards);
    this.recalculateCardsPositions(cards);
  }

  updateCardsInterfaces(cards) {
    cards.forEach((card) => {
      card.setContainer(this);
      card.setInteractive(false);
    });
  }

  recalculateCardsPositions(cards, forcePosition = false) {
    const zIndexOffset = 1 / cards.length;

    cards.forEach((card, index) => {
      card.setZIndex(Z_INDEX.DISCARD + index * zIndexOffset);

      const newPositon = this.position.clone();

      if (forcePosition) {
        card.setPosition(newPositon);
      } else {
        card.setDestination(newPositon);
      }
    });
  }

  afterResize() {
    const cards = this.kingdom.getDiscard();

    this.updatePosition();
    this.recalculateCardsPositions(cards);
  }
}
