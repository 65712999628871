const SOUND_VOLUME_KEY = '-settings-sound-volume';

export class SettingsController {
  constructor(game) {
    this.game = game;

    this.loadFromLocalStorage();
  }

  loadFromLocalStorage() {
    this.soundVolume = localStorage.getItem(SOUND_VOLUME_KEY) || 0.65;
  }

  setSoundVolume(value) {
    this.soundVolume = value;

    localStorage.setItem(SOUND_VOLUME_KEY, value);
  }

  getSoundVolume() {
    return this.soundVolume;
  }
}
