import { CARDS } from 'common/consts/types/cards.js';

import { EnemyKingdom } from '../enemy.js';

export class SniperCannonKingdom extends EnemyKingdom {
  onPlayTimer() {
    this.tryPlayRandom();
  }

  startingCards() {
    const cards = [
      this.createCard({ type: CARDS.PLACE_SNIPER_CANNON }),
      this.createCard({ type: CARDS.FORTIFICATE_MAIN }),
      this.createCard({ type: CARDS.FORTIFICATE_MAIN }),
      this.createCard({ type: CARDS.ADD_ENERGY }),
      this.createCard({ type: CARDS.ADD_ENERGY }),
      this.createCard({ type: CARDS.SPREAD_SHOT }),
      this.createCard({ type: CARDS.SPREAD_SHOT }),
      this.createCard({ type: CARDS.SPREAD_SHOT }),
      this.createCard({ type: CARDS.SNIPER_FIRE }),
      this.createCard({ type: CARDS.JUNK }),
      this.createCard({ type: CARDS.JUNK }),
      this.createCard({ type: CARDS.JUNK }),
      this.createCard({ type: CARDS.JUNK }),
      this.createCard({ type: CARDS.FORTIFICATE_MAIN }),
    ];

    if (this.difficulty >= 2) cards.push(this.createCard({ type: CARDS.SNIPER_FIRE }));
    if (this.difficulty >= 2) cards.push(this.createCard({ type: CARDS.SNIPER_FIRE }));
    if (this.difficulty >= 2) cards.push(this.createCard({ type: CARDS.FORTIFICATE_OWN_FIELD }));
    if (this.difficulty >= 2) cards.push(this.createCard({ type: CARDS.RANDOM_FORTIFICATION }));
    if (this.difficulty >= 2) cards.push(this.createCard({ type: CARDS.DRAW_CARDS }));
    if (this.difficulty >= 2) cards.push(this.createCard({ type: CARDS.ADD_ENERGY }));

    if (this.difficulty >= 3) cards.push(this.createCard({ type: CARDS.PLACE_POWER_STATION }));
    if (this.difficulty >= 3) cards.push(this.createCard({ type: CARDS.PLACE_POWER_STATION }));
    if (this.difficulty >= 3) cards.push(this.createCard({ type: CARDS.FORTIFICATE_AROUND_BASE }));
    if (this.difficulty >= 3) cards.push(this.createCard({ type: CARDS.PLACE_SNIPER_CANNON }));
    if (this.difficulty >= 3) cards.push(this.createCard({ type: CARDS.ADD_ENERGY }));

    return cards;
  }

  pickNewTarget() {
    if (this.cyclesCount % 6 === 0) super.pickNewTarget();
  }
}
