import { COLORS } from 'common/consts/colors.js';
import { CARDS } from 'common/consts/types/cards.js';
import { EFFECTS } from 'common/consts/types/effects.js';
import { IMAGES } from 'common/consts/types/images.js';

import { TOOLTIP } from '../consts/tooltip.js';

export const ADDITIONAL_CARDS = {
  [CARDS.JUNK]: {
    type: CARDS.JUNK,
    title: 'Junk',
    image: IMAGES.CARDS.IMAGES.JUNK,
    color: COLORS.CARD.GRAY,
    effects: [EFFECTS.DESTROY, EFFECTS.DRAIN],
    stats: {
      drain: 500,
    },
    tooltipTypes: [TOOLTIP.TYPE.DRAIN],
  },
};
