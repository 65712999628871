import { Button } from '../button';
import { useDispatch } from 'react-redux';

import { goBackView } from 'store/temporary/slice';

export function BackButton() {
  const dispatch = useDispatch();

  const onClick = () => {
    dispatch(goBackView());
  };

  return (
    <Button onClick={onClick} theme="abort">
      Back
    </Button>
  );
}
