import { EFFECTS_BAR_TOP, EFFECTS_GAP, EFFECTS_ICON_SIZE } from 'client/consts/layout.js';

import { EFFECTS } from 'common/consts/types/effects.js';
import { Point } from 'common/data-types/point.js';
import { PoisonEffect } from 'common/entities/effects/poison.js';
import { VoidEffect } from 'common/entities/effects/void.js';
import { EntityBase } from 'common/entities/entity-base.js';

export class Effects extends EntityBase {
  constructor(params = {}) {
    super(params);

    this.effectsMap = {
      [EFFECTS.POISON]: PoisonEffect,
      [EFFECTS.VOID]: VoidEffect,
    };

    this.effects = [];

    this.kingdom = params.kingdom;

    this.visible = params.visible;
  }

  add(params) {
    const { type } = params;

    const existingEffect = this.effects.find((effect) => effect.type === type);

    if (existingEffect) {
      existingEffect.increase(params);
      if (this.game.shouldSendEvent()) this.game.connectionController.onUpdateEffect(existingEffect);
      return;
    }

    const klass = this.typeToClass(type);

    const effect = new klass({
      ...params,
      game: this.game,
      kingdom: this.kingdom,
      parent: this,
      visible: this.visible,
    });

    this.effects.push(effect);

    if (this.interfacePart) this.repositionEffects();
    if (this.game.shouldSendEvent()) this.game.connectionController.onAddEffect(effect);
  }

  update(params) {
    const { id } = params;

    const effect = this.effects.find((effect) => effect.id === id);
    if (!effect) return;

    effect.update(params);

    if (this.game.shouldSendEvent()) this.game.connectionController.onUpdateEffect(effect);
  }

  onEffectUpdate(effect) {
    if (this.game.shouldSendEvent()) this.game.connectionController.onUpdateEffect(effect);
  }

  remove(id) {
    const index = this.effects.findIndex((effect) => effect.id === id);

    if (index < 0) return;

    const effect = this.effects[index];

    effect.destroy();
    this.effects.splice(index, 1);

    if (this.interfacePart) this.repositionEffects();
    if (this.game.shouldSendEvent()) this.game.connectionController.onRemoveEffect(effect);
  }

  repositionEffects() {
    const max = this.effects.length;

    let offset = (-(max - 1) * (EFFECTS_ICON_SIZE + EFFECTS_GAP)) / 2;

    for (let i = 0; i < max; i++) {
      const effect = this.effects[i];

      effect.setPosition(new Point(window.innerWidth / 2 + offset, EFFECTS_BAR_TOP + EFFECTS_ICON_SIZE / 2));

      offset += 60;
    }
  }

  destroy() {
    this.effects.forEach((effect) => effect.destroy());
    this.effects = [];
    super.destroy();
  }

  triggerRedraw() {
    this.eachEffect((effect) => effect.onKingdomRedraw());
  }

  afterResize() {
    this.repositionEffects();
  }

  typeToClass(type) {
    return this.effectsMap[type];
  }

  eachEffect(callback) {
    this.effects.forEach(callback);
  }

  toSocketData() {
    return this.effects.map((effect) => effect.toSocketData());
  }
}
