import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { FLUSH, PAUSE, PERSIST, PURGE, REGISTER, REHYDRATE, persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import mainReducer from './main/slice';
import temporaryReducer from './temporary/slice';
import websocketReducer from './websocket/slice';

const persistConfig = {
  key: 'root',
  blacklist: ['temporary', 'websocket'],
  storage,
};

const rootReducer = combineReducers({
  main: mainReducer,
  temporary: temporaryReducer,
  websocket: websocketReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      immutableCheck: false,
      serializableCheck: {
        ignoredActionPaths: ['payload.connection'],
        ignoredPaths: ['websocket.connection'],
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

export const persistor = persistStore(store);
