import { CARDS } from 'common/consts/types/cards.js';

import { EnemyKingdom } from '../enemy.js';

export class VoiderKingdom extends EnemyKingdom {
  onPlayTimer() {
    this.tryPlayRandom();
  }

  startingCards() {
    const cards = [
      this.createCard({ type: CARDS.FORTIFICATE_MAIN }),
      this.createCard({ type: CARDS.ADD_ENERGY }),
      this.createCard({ type: CARDS.ADD_ENERGY }),
      this.createCard({ type: CARDS.ADD_ENERGY }),
      this.createCard({ type: CARDS.FORTIFICATE_AND_PLACE_REINFORCEMENT }),
      this.createCard({ type: CARDS.FORTIFICATE_AND_PLACE_REINFORCEMENT }),
      this.createCard({ type: CARDS.FORTIFICATE_AND_PLACE_REINFORCEMENT }),
      this.createCard({ type: CARDS.FORTIFICATE_AND_PLACE_REINFORCEMENT }),
      this.createCard({ type: CARDS.FORTIFICATE_AND_PLACE_REINFORCEMENT }),
      this.createCard({ type: CARDS.PLACE_FORGE }),
      this.createCard({ type: CARDS.PLACE_POWER_STATION }),
      this.createCard({ type: CARDS.SPREAD_SHOT }),
      this.createCard({ type: CARDS.FORTIFICATE_MAIN }),
      this.createCard({ type: CARDS.INCREASE_CARD_LIMIT }),
      this.createCard({ type: CARDS.INCREASE_CARD_LIMIT }),
    ];

    if (this.difficulty >= 2) cards.push(this.createCard({ type: CARDS.JUNK }));
    if (this.difficulty >= 2) cards.push(this.createCard({ type: CARDS.JUNK }));
    if (this.difficulty >= 2) cards.push(this.createCard({ type: CARDS.JUNK }));
    if (this.difficulty >= 2) cards.push(this.createCard({ type: CARDS.ADD_ENERGY }));
    if (this.difficulty >= 2) cards.push(this.createCard({ type: CARDS.INCREASE_CARD_LIMIT }));
    if (this.difficulty >= 2) cards.push(this.createCard({ type: CARDS.INCREASE_CARD_LIMIT }));

    if (this.difficulty >= 3) cards.push(this.createCard({ type: CARDS.ADD_ENERGY }));
    if (this.difficulty >= 3) cards.push(this.createCard({ type: CARDS.ADD_ENERGY }));
    if (this.difficulty >= 3) cards.push(this.createCard({ type: CARDS.INCREASE_CARD_LIMIT }));
    if (this.difficulty >= 3) cards.push(this.createCard({ type: CARDS.INCREASE_CARD_LIMIT }));

    return cards;
  }

  after60Cycles() {
    this.discard.push(this.createCard({ type: CARDS.DESTROY_FIELD }));
    this.discard.push(this.createCard({ type: CARDS.DESTROY_FIELD }));
    this.discard.push(this.createCard({ type: CARDS.DESTROY_FIELD }));

    if (this.difficulty >= 2) this.discard.push(this.createCard({ type: CARDS.DESTROY_FIELD }));
    if (this.difficulty >= 2) this.discard.push(this.createCard({ type: CARDS.DESTROY_FIELD }));

    if (this.difficulty >= 3) this.discard.push(this.createCard({ type: CARDS.DESTROY_FIELD }));
    if (this.difficulty >= 3) this.discard.push(this.createCard({ type: CARDS.DESTROY_FIELD }));
  }
}
