import { CARDS } from 'common/consts/types/cards.js';

import { Kingdom } from '../kingdom.js';

export class HumanKingdom extends Kingdom {
  startingCards() {
    return [
      this.createCard({ type: CARDS.SPREAD_SHOT }),
      this.createCard({ type: CARDS.SPREAD_SHOT }),

      this.createCard({ type: CARDS.SNIPER_FIRE }),
      this.createCard({ type: CARDS.SNIPER_FIRE }),

      this.createCard({ type: CARDS.FORTIFICATE_OWN_FIELD }),
      this.createCard({ type: CARDS.FORTIFICATE_OWN_FIELD }),

      this.createCard({ type: CARDS.RANDOM_FORTIFICATION }),
      this.createCard({ type: CARDS.RANDOM_FORTIFICATION }),

      this.createCard({ type: CARDS.SPARK }),
      this.createCard({ type: CARDS.SPARK }),
      this.createCard({ type: CARDS.SPARK }),
      this.createCard({ type: CARDS.SPARK }),

      this.createCard({ type: CARDS.JUNK }),
      this.createCard({ type: CARDS.JUNK }),
      this.createCard({ type: CARDS.JUNK }),
      this.createCard({ type: CARDS.JUNK }),
    ];
  }

  setup() {
    super.setup();

    this.addStartingFortification();
  }

  addStartingFortification() {
    if (!this.difficulty) return;

    let fortification = 0;

    if (this.difficulty == 1) fortification += 100;
    if (this.difficulty == 2) fortification += 30;

    this.addMainFortification(fortification);
  }
}
