import { CANNONS_ROTATION_SPEED } from 'client/consts/interface.js';
import { HEXAGON_SIZE } from 'client/consts/layout.js';

import { BASE_CANNONS_INACCURACY } from 'common/consts/structures.js';
import { ENTITIES } from 'common/consts/types/entities.js';
import { degreesToRadians, fullAngleRadians, halfAngleRadians, perFrameSpeed, perFrameTimer } from 'common/helpers/converters.js';
import { randomDecimal } from 'common/helpers/random.js';
import { rotationToSpeeds } from 'common/helpers/rotation.js';

import { BaseBuilding } from '../base.js';

export class BaseCannon extends BaseBuilding {
  constructor(params) {
    super(params);

    this.rotate = true;
    this.rotation = 0;
    this.isCannon = true;

    this.setRotationToOwner();
    if (this.interfacePart) this.setSpriteRotation();

    this.fireInaccuracy = BASE_CANNONS_INACCURACY;
    this.range = HEXAGON_SIZE * 33;
    this.bulletSpeed = HEXAGON_SIZE * 5;
  }

  getSpriteName() {}

  setSpriteRotation() {
    if (!this.rotate) return false;

    this.spriteRotation = this.rotation;
    this.sprite.rotation = this.rotation;
  }

  getFieldByHex() {
    return this.mapField;
  }

  onCycleEnd() {
    if (this.owner && this.advancedLogicPart) this.fireBullets();
  }

  activate(delta) {
    if (this.interfacePart) this.rotateSprite(delta);
  }

  rotateSprite(delta) {
    if (!this.rotate) return false;
    if (this.spriteRotation === this.rotation) return false;

    const diff = this.rotation - this.spriteRotation;
    const rotationSpeed = CANNONS_ROTATION_SPEED * delta;

    if (Math.abs(diff) > rotationSpeed) {
      this.spriteRotation += rotationSpeed * (diff >= 0 ? (diff >= halfAngleRadians ? -1 : 1) : diff >= -halfAngleRadians ? -1 : 1);

      if (this.spriteRotation < 0) this.spriteRotation = fullAngleRadians;
      if (this.spriteRotation > fullAngleRadians) this.spriteRotation = 0;
    } else {
      this.spriteRotation = this.rotation;
    }

    this.sprite.rotation = this.spriteRotation;
  }

  fireBullets(params = {}) {
    const { spread = 1, inaccuracy = this.fireInaccuracy, power, piercing, targets = [this.owner.target] } = params;

    targets.forEach((target) => {
      const rotation = target.clone().subtract(this.position).getRotation();

      for (let i = 0; i < spread; i++) {
        this.game.entitiesController.create(ENTITIES.BULLET, {
          x: this.position.x,
          y: this.position.y,
          ...rotationToSpeeds(rotation + degreesToRadians(randomDecimal(-inaccuracy / 2, inaccuracy / 2)), perFrameSpeed(this.bulletSpeed)),
          lifeTime: perFrameTimer(this.range / this.bulletSpeed),
          owner: this.owner,
          power,
          piercing,
        });
      }
    });
  }

  setRotationToOwner() {
    if (this.owner) this.setRotationToPoint(this.owner.target);
  }

  setRotationToPoint(point) {
    this.rotation = point.clone().subtract(this.position).getRotation();
  }
}
