import { HEXAGON_HEIGHT, HEXAGON_RADIUS } from 'client/consts/layout.js';

import { Point } from './point.js';

export class HexPosition {
  constructor(col = 0, row = 0) {
    this.col = col;
    this.row = row;
  }

  toPoint() {
    const verticalDistance = HEXAGON_RADIUS * 1.5;

    const x = this.col * HEXAGON_HEIGHT * 2 + !(this.row % 2) * HEXAGON_HEIGHT;
    const y = this.row * verticalDistance;

    return new Point(x, y);
  }

  isSame(hexPosition) {
    return this.col === hexPosition.col && this.row === hexPosition.row;
  }
}
