import * as PIXI from 'pixi.js';

import { CYCLE_BAR_BORDER, CYCLE_BAR_HEIGHT, CYCLE_BAR_TOP, CYCLE_BAR_WIDTH } from 'client/consts/layout.js';
import { Z_INDEX } from 'client/consts/z-index.js';
import { InterfaceBase } from 'client/interface/interface-base.js';

export class CycleBar extends InterfaceBase {
  constructor(params) {
    super(params);

    this.createSprite();
    this.setPosition();
  }

  setPosition() {
    this.spritesContainer.x = window.innerWidth / 2;
    this.spritesContainer.y = CYCLE_BAR_TOP;
  }

  createSprite() {
    this.spritesContainer = new PIXI.Container();
    this.spritesContainer.zIndex = Z_INDEX.CYCLE_BAR;

    this.barBackSprite = new PIXI.Graphics();
    this.barBackSprite
      .rect(-CYCLE_BAR_WIDTH / 2, -CYCLE_BAR_HEIGHT / 2, CYCLE_BAR_WIDTH, CYCLE_BAR_HEIGHT)
      .stroke({ color: 0x5c5c5c, width: CYCLE_BAR_BORDER });

    this.barSprite = new PIXI.Graphics();
    this.barSprite
      .rect(0, -CYCLE_BAR_HEIGHT / 2 + CYCLE_BAR_BORDER, CYCLE_BAR_WIDTH - CYCLE_BAR_BORDER * 2, CYCLE_BAR_HEIGHT - CYCLE_BAR_BORDER * 2)
      .fill({ color: 'white' });

    this.barSprite.x = -CYCLE_BAR_WIDTH / 2 + CYCLE_BAR_BORDER;

    this.timeText = new PIXI.Text({
      text: '',
      style: {
        fontFamily: 'Arial',

        fontSize: 40,
        fill: 0xffffff,
        stroke: {
          width: 5,
          color: 0x444444,
          join: 'round',
        },
      },
    });
    this.timeText.anchor.set(0.5);

    this.spritesContainer.addChild(this.barBackSprite);
    this.spritesContainer.addChild(this.barSprite);
    this.spritesContainer.addChild(this.timeText);

    this.updateSprites();
    this.setSpritesPositions();

    this.registerSprite(this.spritesContainer);
  }

  setSpritesPositions() {
    this.barBackSprite.y = CYCLE_BAR_HEIGHT / 2;
    this.barSprite.y = CYCLE_BAR_HEIGHT / 2;
    this.timeText.y = CYCLE_BAR_HEIGHT / 2 + 1;
  }

  updateSprites() {
    const cycleProgress = this.game.cycleProgress();
    const cycleTime = this.game.getCycleTime();

    if (cycleTime === null) {
      this.barSprite.width = 0;
      this.timeText.text = '-';

      return;
    }

    this.barSprite.width = cycleProgress * (CYCLE_BAR_WIDTH - CYCLE_BAR_BORDER * 2);
    this.timeText.text = (cycleTime * (1 - cycleProgress)).toFixed(1) + 's';
  }

  destroy() {
    super.destroy();
    this.game.interfaceController.removeSprite(this.spritesContainer);
  }

  activate() {
    this.updateSprites();
  }

  afterResize() {
    this.setPosition();
  }
}
