import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';

import { persistor, store } from 'store/setup';

import { Navigator } from './core/navigator';

// import { WebsocketManager } from './core/websocket-manager';

export function App() {
  return (
    <Provider store={store}>
      <PersistGate loading={<p>LOADING</p>} persistor={persistor}>
        {/*<WebsocketManager />*/}
        <Navigator />
      </PersistGate>
    </Provider>
  );
}
