import { FRAMES_PER_SECOND } from 'common/consts/game.js';

export function radiansToDegrees(radians) {
  return (radians * 180) / Math.PI;
}

export function degreesToRadians(degrees) {
  return (degrees / 180) * Math.PI;
}

export function perFrameSpeed(value) {
  return value / FRAMES_PER_SECOND;
}

export function perFrameTimer(value) {
  return value * FRAMES_PER_SECOND;
}

export const rightAngleRadians = degreesToRadians(90);
export const halfAngleRadians = degreesToRadians(180);
export const fullAngleRadians = degreesToRadians(360);

export function between(min, value, max) {
  return Math.max(min, Math.min(value, max));
}
