import { ENTITIES } from 'common/consts/types/entities.js';
import { IMAGES } from 'common/consts/types/images.js';

import { EntityBase } from './entity-base.js';
import { FortificationIcon } from './fortification-icon.js';

const ZOOM_THRESHOLD = 0.5;

export class MapField extends EntityBase {
  constructor(params) {
    super(params);

    this.hexPosition = params.hexPosition;
    this.position = this.hexPosition.toPoint();

    this.structure = null;

    this.owner = params.owner;
    if (this.owner) this.owner.onGainTile();

    this.distance = 0;

    this.type = ENTITIES.FIELD;

    if (this.interfacePart) this.createSprite();

    this.fortification = 0;
    this.voided = false;
  }

  createSprite() {
    this.sprite = this.game.texturesManager.createStandardSprite(IMAGES.ENTITIES.FIELD);
    this.registerMapSprite(this.sprite);

    this.sprite.tint = this.owner ? this.owner.color : '0xFFFFFF';

    this.setSpritePosition();
  }

  setSpritePosition() {
    this.sprite.x = this.position.x;
    this.sprite.y = this.position.y;
  }

  isType(type) {
    return this.type === type;
  }

  getDamage(power, kingdom) {
    let leftPower = power;

    const fortificationDamage = Math.min(leftPower, this.fortification);

    this.changeFortification(-fortificationDamage);

    leftPower -= fortificationDamage;

    if (leftPower === 0) return 0;

    leftPower -= this.damageBuilding(leftPower);

    if (leftPower === 0) return 0;

    this.changeOwner(kingdom);

    leftPower -= 1;

    return leftPower;
  }

  getVoidDamage(power) {
    const leftPower = this.getDamage(power, null);

    if (this.owner === null) {
      this.voided = true;
      if (this.interfacePart) this.sprite.visible = false;
    }

    return leftPower;
  }

  void() {
    this.voided = true;
    this.owner = null;

    this.destroyBuilding();
    this.dropFortifications();

    if (this.interfacePart) this.sprite.visible = false;
  }

  dropFortifications() {
    this.setFortification(0);
  }

  setFortification(value) {
    this.fortification = value;
    this.onFortificationUpdate();
  }

  changeFortification(value) {
    this.fortification += value;
    this.onFortificationUpdate();
  }

  onFortificationUpdate() {
    if (this.interfacePart) this.updateFortificationInterface();
    if (this.game.shouldSendEvent()) this.game.connectionController.onFieldUpdate(this);
  }

  updateFortificationInterface() {
    if (this.fortificationIcon) {
      if (this.fortification <= 0) {
        this.fortificationIcon.destroy();
        this.fortificationIcon = null;
      } else {
        this.fortificationIcon.onFortificationUpdate();
      }
    } else {
      if (this.fortification > 0) {
        this.fortificationIcon = new FortificationIcon({
          x: this.position.x,
          y: this.position.y,
          owner: this.owner,
          mapField: this,
          game: this.game,
        });
      }
    }
  }

  getFortification() {
    return this.fortification;
  }

  saveDistanceFor(point) {
    this.distance = this.position.distance(point);
  }

  getPosition() {
    return this.position.clone();
  }

  setStructure(structure) {
    this.structure = structure;
  }

  rotateBuilding(point) {
    if (this.structure) this.structure.setRotationToPoint(point);
  }

  empty() {
    return this.structure === null;
  }

  ownedBy(kingdom) {
    if (!this.owner && !kingdom) return true;
    if (!this.owner || !kingdom) return false;

    return this.owner.id === kingdom.id;
  }

  damageBuilding(damage) {
    if (!this.structure) return 0;
    return this.structure.onDamage(damage);
  }

  destroyBuilding() {
    if (!this.structure) return;

    this.structure.destroy();
    this.structure = null;
  }

  onBuildingDestroy() {
    this.structure = null;
  }

  setOwner(owner) {
    this.owner = owner;
    if (this.owner) this.owner.onGainTile();

    if (this.interfacePart) {
      this.sprite.tint = owner ? owner.color : 0xffffff;
      this.fortificationIcon && this.fortificationIcon.changeOwner(newOwner);
    }

    this.structure && this.structure.setOwner(owner);
  }

  changeOwner(owner) {
    if (this.owner) this.owner.onLoseTile();
    this.owner = owner;
    if (this.owner) this.owner.onGainTile();

    if (this.interfacePart) {
      this.sprite.tint = owner ? owner.color : 0xffffff;
      this.fortificationIcon && this.fortificationIcon.changeOwner(owner);
    }

    if (this.game.shouldSendEvent()) this.game.connectionController.onFieldUpdate(this);
  }

  allAdjacentFields() {
    const { col, row } = this.hexPosition;

    return [
      this.game.mapController.getFieldByCoords(col - 1, row),
      this.game.mapController.getFieldByCoords(col + (row % 2 ? -1 : 1), row - 1),
      this.game.mapController.getFieldByCoords(col, row - 1),
      this.game.mapController.getFieldByCoords(col + 1, row),
      this.game.mapController.getFieldByCoords(col + (row % 2 ? -1 : 1), row + 1),
      this.game.mapController.getFieldByCoords(col, row + 1),
    ];
  }

  adjacentToEnemy() {
    const fields = this.allAdjacentFields();

    for (let i = 0; i < fields.length; i++) {
      const field = fields[i];

      if (field) {
        if (!field.ownedBy(this.owner) && !field.isType(ENTITIES.WALL)) {
          return true;
        }
      }
    }

    return false;
  }

  activate() {}

  onCycleEnd() {}

  getOwner() {
    return this.owner;
  }

  onZoom(zoomValue) {
    this.sprite.texture = this.game.texturesManager.getTexture(
      zoomValue >= ZOOM_THRESHOLD ? IMAGES.ENTITIES.FIELD : IMAGES.ENTITIES.SMALL_FIELD
    );
  }

  toSocketData() {
    const data = { id: this.id, type: this.type };

    if (this.structure) data.structure = this.structure.toSocketData();
    if (this.owner) data.hostId = this.owner.id;
    if (this.fortification) data.fortification = this.fortification;

    return data;
  }

  updateFromSocketData(data) {
    const { hostId, fortification = 0, structure } = data;

    if (this.owner?.id !== hostId) {
      const kingdom = this.game.kingdomsController.getKingdomById(hostId);
      this.changeOwner(kingdom);
    }

    if (fortification !== this.fortification) {
      this.setFortification(fortification);
    }

    if (structure) {
      if (!this.structure) {
        this.game.structuresCreator.createStructureFromSD(this, structure);
      }
    } else {
      this.destroyBuilding();
    }
  }

  isCollisionable() {
    return !this.voided;
  }

  destroy() {
    super.destroy();

    if (this.interfacePart) this.removeMapSprite(this.sprite);
    if (this.interfacePart && this.fortificationIcon) this.fortificationIcon.destroy();

    this.destroyBuilding();
  }
}
