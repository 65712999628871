import { Sound, sound } from '@pixi/sound';

import drawing1Sound from 'client/assets/sounds/drawing1.mp3';
import drawing2Sound from 'client/assets/sounds/drawing2.mp3';
import drawing3Sound from 'client/assets/sounds/drawing3.mp3';
import selectingCard1Sound from 'client/assets/sounds/selecting-card1.mp3';
import selectingCard2Sound from 'client/assets/sounds/selecting-card2.mp3';
import selectingCard3Sound from 'client/assets/sounds/selecting-card3.mp3';
import selectingField1Sound from 'client/assets/sounds/selecting-field1.mp3';
import selectingField2Sound from 'client/assets/sounds/selecting-field2.mp3';
import selectingField3Sound from 'client/assets/sounds/selecting-field3.mp3';
import shuffling1Sound from 'client/assets/sounds/shuffling1.mp3';
import shuffling2Sound from 'client/assets/sounds/shuffling2.mp3';
import shuffling3Sound from 'client/assets/sounds/shuffling3.mp3';
import { SOUNDS } from 'client/consts/sounds.js';

import { randomDecimal, randomIn } from 'common/helpers/random.js';

export class SoundsController {
  constructor(game) {
    this.game = game;

    this.sounds = {};

    sound._webAudioContext.playEmptySound();

    this.loadSounds();
  }

  loadSounds() {
    this.loadSound(drawing1Sound, SOUNDS.DRAWING);
    this.loadSound(drawing2Sound, SOUNDS.DRAWING);
    this.loadSound(drawing3Sound, SOUNDS.DRAWING);
    this.loadSound(selectingCard1Sound, SOUNDS.SELECTING_CARD);
    this.loadSound(selectingCard2Sound, SOUNDS.SELECTING_CARD);
    this.loadSound(selectingCard3Sound, SOUNDS.SELECTING_CARD);
    this.loadSound(selectingField1Sound, SOUNDS.SELECTING_FIELD);
    this.loadSound(selectingField2Sound, SOUNDS.SELECTING_FIELD);
    this.loadSound(selectingField3Sound, SOUNDS.SELECTING_FIELD);
    this.loadSound(shuffling1Sound, SOUNDS.SHUFFLING);
    this.loadSound(shuffling2Sound, SOUNDS.SHUFFLING);
    this.loadSound(shuffling3Sound, SOUNDS.SHUFFLING);
  }

  loadSound(path, name) {
    if (!this.sounds[name]) this.sounds[name] = [];

    this.sounds[name].push(
      Sound.from({
        url: path,
        preload: true,
      })
    );
  }

  playSound(name, params = {}) {
    const soundArray = this.sounds[name];

    if (!soundArray) return;
    if (soundArray.length < 1) return;

    const { speedRange, soundIndex } = params;

    const sound = soundIndex ? soundArray[soundIndex] : randomIn(soundArray);

    if (!sound.isLoaded) return;

    sound.speed = speedRange ? randomDecimal(1 - speedRange, 1 + speedRange) : 1;
    sound.volume = this.game.settingsController.getSoundVolume();

    sound.play();
  }
}
