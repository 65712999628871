import * as PIXI from 'pixi.js';

import { InterfaceBase } from '../interface-base.js';

export class InterfaceElement extends InterfaceBase {
  constructor(params) {
    super(params);

    this.zIndex = params.zIndex || 1;

    this.marginTop = 0;
  }

  verticalSize() {
    return this.height + this.marginTop;
  }

  createContainer() {
    this.sprites.container = new PIXI.Container();
    this.sprites.container.zIndex = this.zIndex;
    this.sprites.container.sortableChildren = true;

    this.registerSprite(this.sprites.container);
  }
}
