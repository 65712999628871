import { COLORS } from 'common/consts/colors.js';
import { TOOLTIP } from 'common/consts/tooltip.js';
import { CARDS } from 'common/consts/types/cards.js';
import { EFFECTS } from 'common/consts/types/effects.js';
import { IMAGES } from 'common/consts/types/images.js';

export const STARTING_CARDS = {
  [CARDS.SPARK]: {
    type: CARDS.SPARK,
    title: 'A Spark',
    image: IMAGES.CARDS.IMAGES.SPARK,
    effects: [EFFECTS.ADD_ENERGY],
    stats: {
      energy: 100,
    },
    color: COLORS.CARD.GREEN,
  },
  [CARDS.SPREAD_SHOT]: {
    type: CARDS.SPREAD_SHOT,
    title: 'Spread Shot',
    image: IMAGES.CARDS.IMAGES.SPREAD_SHOT,
    effects: [EFFECTS.SHOT],
    stats: {
      spread: 10,
    },
    color: COLORS.CARD.RED,
    tooltipTypes: [TOOLTIP.TYPE.SPREAD],
  },
  [CARDS.SNIPER_FIRE]: {
    type: CARDS.SNIPER_FIRE,
    title: 'Sniper fire',
    image: IMAGES.CARDS.IMAGES.SNIPER_FIRE,
    effects: [EFFECTS.SHOT],
    stats: {
      inaccuracy: 0,
      shots: 5,
    },
    color: COLORS.CARD.RED,
    tooltipTypes: [TOOLTIP.TYPE.SNIPER],
  },
  [CARDS.FORTIFICATE_OWN_FIELD]: {
    type: CARDS.FORTIFICATE_OWN_FIELD,
    title: 'Instant Wall',
    image: IMAGES.CARDS.IMAGES.FORTIFICATE_OWN_FIELD,
    effects: [EFFECTS.FORTIFICATE_OWN_FIELD],
    stats: {
      fortification: 7,
    },
    color: COLORS.CARD.BLUE,
    tooltipTypes: [TOOLTIP.TYPE.FORTIFICATION],
  },
  [CARDS.RANDOM_FORTIFICATION]: {
    type: CARDS.RANDOM_FORTIFICATION,
    title: 'Fortificate everything',
    image: IMAGES.CARDS.IMAGES.RANDOM_FORTIFICATION,
    effects: [EFFECTS.RANDOM_FORTIFICATION],
    stats: {
      fortification: 2,
      fields: 6,
    },
    color: COLORS.CARD.BLUE,
    tooltipTypes: [TOOLTIP.TYPE.FORTIFICATION],
  },
};
