import { POWER_PLANT_PRODUCTION } from 'common/consts/structures.js';
import { IMAGES } from 'common/consts/types/images.js';
import { STRUCTURES } from 'common/consts/types/structures.js';

import { BaseBuilding } from '../base.js';

export class PowerPlantBuilding extends BaseBuilding {
  constructor(params) {
    super({ ...params, uncolorable: true });

    this.type = STRUCTURES.POWER_STATION;

    this.owner.changeEnergyProduction(POWER_PLANT_PRODUCTION);
  }

  getSpriteName() {
    return IMAGES.STRUCTURES.POWER_STATION;
  }

  onDestroy() {
    this.owner.changeEnergyProduction(-POWER_PLANT_PRODUCTION);
  }
}
