import { useDispatch } from 'react-redux';

import { openView } from 'store/temporary/slice';

import styles from './styles.module.css';

export function PreparationRow({ preparation }) {
  const dispatch = useDispatch();

  const { id, name, players, maxPlayers, cycleTime, mapSize } = preparation;

  const numberOfPlayers = players.length;

  const onClick = () => {
    dispatch(openView({ viewName: 'preparation-launching', viewParams: { preparationId: id } }));
  };

  return (
    <div className={styles.container} onClick={onClick}>
      <div className={styles.name}>{name}</div>
      <div className={styles.details}>
        {cycleTime}s/{mapSize}h
      </div>
      <div className={styles.details}>
        {numberOfPlayers}/{maxPlayers}
      </div>
    </div>
  );
}
