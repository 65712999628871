import { uniqueString } from 'common/helpers/data.js';

import { CardsBoard } from './cards-board.js';

export class MarketBoard extends CardsBoard {
  constructor(params) {
    super(params);

    this.type = params.type;

    this.cardsTypes = [];

    this.setCardsTypes();
    this.calculateLayout();
    this.createSprites();
    this.updateInterface();

    this.createCards();
    this.setCardsPositions();

    this.registerEventListeners();
  }

  getNumberOfCards() {
    return this.cardsTypes.length;
  }

  setCardsTypes() {
    this.cardsTypes = this.game.marketController.getChoices(this.type);
  }

  recreateCards() {
    this.destroyAllCards();

    this.setCardsTypes();
    this.calculateLayout();

    this.createCards();
    this.setCardsPositions();
  }

  registerEventListeners = () => {
    this.game.eventsController.addListener('escKeyDown', this);
    this.game.eventsController.addListener('closeMarket', this);
    this.game.eventsController.addListener('openMarket', this);
    this.game.eventsController.addListener('marketReload', this);
    this.game.eventsController.addListener('blockMarketInteractive', this);
    this.game.eventsController.addListener('unblockMarketInteractive', this);
  };

  destroyAllCards = () => {
    this.cards.forEach((card) => {
      card.destroy();
    });

    this.cards = [];
  };

  createCards = () => {
    this.cards = [];

    this.cardsTypes.forEach((cardType) => {
      const card = this.game.cardsCreator.createCard({
        type: cardType,
        owner: this,
        zIndex: 105,
        interfaceContainer: this.sprites.boxContainer,
        id: uniqueString('I'),
      });

      this.cards.push(card);
    });

    this.cards.forEach((card) => {
      card.showFront();

      card.setContainer(this);
      card.setInteractive(this.interactive);
    });
  };

  onEscKeyDown = () => {
    if (!this.visible) return false;

    this.game.eventsController.runEvent('closeMarket');

    return true;
  };

  onCloseMarket = () => {
    this.setVisibility(false);
  };

  onOpenMarket = (params) => {
    const { type } = params;

    this.setVisibility(type === this.type);
  };

  onMarketReload = () => {
    this.recreateCards();
  };

  onBlockMarketInteractive = () => {
    this.interactiveBlockades++;
    if (this.interactiveBlockades === 1) this.setInteractive(false);
  };

  onUnblockMarketInteractive = () => {
    this.interactiveBlockades--;
    if (this.interactiveBlockades === 0) this.setInteractive(true);
  };

  destroy() {
    super.destroy();
    this.unregisterEventListeners();
  }

  unregisterEventListeners = () => {
    this.game.eventsController.removeListener('escKeyDown', this);
    this.game.eventsController.removeListener('closeMarket', this);
    this.game.eventsController.removeListener('openMarket', this);
    this.game.eventsController.removeListener('marketReload', this);
    this.game.eventsController.removeListener('blockMarketInteractive', this);
    this.game.eventsController.removeListener('unblockMarketInteractive', this);
  };

  onCardClick(card) {
    this.game.eventsController.runEvent('marketCardClick', { card });
  }
}
