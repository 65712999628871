import { useSelector } from 'react-redux';

import { PlayerDetails } from './player-details';

import styles from './styles.module.css';

export function PreparationPlayers({ preparation }) {
  const ownPlayerId = useSelector((state) => state.main.playerId);

  if (!preparation) return null;

  const { players, maxPlayers, hostId } = preparation;

  return (
    <div className={styles.container}>
      <div className={styles.subsectionTitle}>
        Players ({players.length}/{maxPlayers}):
      </div>

      <div className={styles.playersContainer}>
        {players.map((player) => (
          <PlayerDetails key={player.id} player={player} ownPlayerId={ownPlayerId} hostId={hostId} />
        ))}
      </div>
    </div>
  );
}
