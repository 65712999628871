export async function makeRequest(args) {
  const { method = 'GET', body, url, token } = args;

  try {
    const headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    };

    if (token) headers.Authorization = token;

    const fetchParams = {
      method,
      headers,
    };

    if (body) fetchParams.body = JSON.stringify(body);

    const response = await fetch(url, fetchParams);

    return await response.json();
  } catch (e) {
    return { errorMessage: `[${e.name}]: ${e.message}` };
  }
}

export function prepareCallback(args) {
  const { onSuccess, onFailure } = args;

  return (response) => {
    if (response.error) onFailure && onFailure(response);
    onSuccess && onSuccess(response);
  };
}
