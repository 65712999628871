import { EFFECTS } from 'common/consts/types/effects.js';

import { setShotDefaults } from '../helpers/effects-helpers.js';
import { joinAdjectives, pluralize } from '../helpers/text.js';

import { BASE_CANNONS_INACCURACY } from './structures.js';

export const EFFECT_DESCRIPTIONS = {
  [EFFECTS.DESTROY]: 'Destroy.',
  [EFFECTS.LIGHTWEIGHT]: 'Lightweight.',
  [EFFECTS.UNPLAYABLE]: 'Unplayable.',
  [EFFECTS.TEMPORARY]: 'Temporary.',
  [EFFECTS.DRAIN]: 'Drain DRAIN.',
  [EFFECTS.PLACE_POWER_STATION]: 'Place a power station on your empty tile.',
  [EFFECTS.ADD_ENERGY]: 'Add ENERGY energy.',
  [EFFECTS.PLACE_BASIC_CANNON]: 'Place a basic cannon on your empty tile.',
  [EFFECTS.PLACE_SNIPER_CANNON]: 'Place a sniper cannon on your empty tile.',
  [EFFECTS.PLACE_FORGE]: 'Place a forge on your empty tile.',
  [EFFECTS.VOID_TO_OTHERS]: 'Apply VOID_POWER void to other players.',
  [EFFECTS.VOID_TO_ALL]: 'Apply VOID_POWER void to all players.',
  [EFFECTS.ADD_ENERGY]: 'Gain ENERGY energy.',
  [EFFECTS.FORTIFICATE_OWN_FIELD]: 'Add FORTIFICATION fortifications to your field.',
  [EFFECTS.RANDOM_FORTIFICATION]: 'Add FORTIFICATION fortifications to up to FIELDS your random empty fields.',
  [EFFECTS.FORTIFICATE_AROUND_BASE]: 'Add FORTIFICATION fortification to all fields adjacent to the base.',
  [EFFECTS.PLACE_MINE]: 'Place a mine on your empty tile.',
  [EFFECTS.PLACE_MINES_RANDOMLY]: 'Place NUMBER_OF_MINES mines on your random empty tiles.',
  [EFFECTS.PLACE_RANDOM_CANNON_RANDOMLY]: 'Place NUMBER_OF_CANNONS random cannons on your random empty tiles.',
  [EFFECTS.PLACE_DEFENDER]: 'Place a defender on your empty tile.',
  [EFFECTS.PLACE_SHORT_RANGE_CANNON]: 'Place a short-range cannon on your empty tile.',
  [EFFECTS.SHOT]: (stats) => {
    const { spread, power, piercing, inaccuracy, shots } = setShotDefaults(stats);

    const sniperPart = inaccuracy === 0 ? 'sniper ' : '';
    const numberPart = shots > 1 ? `SHOTS ` : `a `;
    const nounPart = pluralize('shot', shots);

    const adjectives = [];

    if (spread > 1) adjectives.push('spread SPREAD');
    if (power > 1) adjectives.push('power POWER');
    if (piercing > 1) adjectives.push('piercing PIERCING');
    if (inaccuracy !== 0 && inaccuracy !== BASE_CANNONS_INACCURACY) adjectives.push('inaccuracy INACCURACY');

    const adjectivesPrefix = adjectives.length > 0 ? ' with ' : '';
    const adjectivesPart = joinAdjectives(adjectives);

    return `Fire ${numberPart}${sniperPart}${nounPart}${adjectivesPrefix}${adjectivesPart}.`;
  },
  [EFFECTS.DESTROY_OWN_BUILDING]: 'Destroy your building.',
  [EFFECTS.INCREASE_SPREAD]: 'Increase the spreed of this card by SPREAD_INCREASE.',
  [EFFECTS.INCREASE_SHOTS]: 'Increase the number of shots of this card by SHOTS_INCREASE.',
  [EFFECTS.DRAW_CARDS]: 'Draw CARDS cards.',
  [EFFECTS.TRASH_CARD]: 'Trash a card from your hand.',
  [EFFECTS.FAIR_SHOT]: 'Fire SHOTS sniper shots at every enemy base.',
  [EFFECTS.FORTIFICATE_BORDERS]: 'Add FORTIFICATION fortifications to all border fields.',
  [EFFECTS.FORTIFICATE_STRUCTURES]: 'Add FORTIFICATION fortifications to all your fields with buildings.',
  [EFFECTS.ENERGY_TO_BULLETS]: 'Spend all energy, fire a shot with spread SPREAD for every energy spent.',
  [EFFECTS.FORTIFICATE_MAIN]: "Add FORTIFICATION fortifications to the main building's field and all your adjacent fields.",
  [EFFECTS.INCREASE_CARD_LIMIT]: 'Increase your cards limit by 1.',
  [EFFECTS.TRASH_AND_COPY_CARD]: 'Trash a persistent card from your hand. Gain COPIES temporary copies of this card.',
  [EFFECTS.FORTIFICATE_AND_PLACE_REINFORCEMENT]:
    'Place an reinforcement on your empty field and add FORTIFICATION fortifications to that field.',
  [EFFECTS.DESTROY_WEAKEST_FIELDS]: (stats) => {
    const { numberOfFields } = stats;
    return `Destroy ${numberOfFields} weakest ${pluralize('field', numberOfFields)} of every enemy.`;
  },
  [EFFECTS.IMPROVE_HEADQUARTER]: 'Increase spread, power, and piercing of the headquarter by LEVEL. Descrease the inaccuracy by 15%.',
  [EFFECTS.REDUCE_DRAIN_RANDOMLY]: 'Reduce drain of a random card in your deck by DRAIN_REDUCTION.',
  [EFFECTS.ACTIVATE_CANNONS]: 'Activate your cannons TIMES times.',
};
