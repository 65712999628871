import { HEXAGON_HEIGHT, HEXAGON_RADIUS } from 'client/consts/layout.js';

import { degreesToRadians, fullAngleRadians, rightAngleRadians } from 'common/helpers/converters.js';

import { HexPosition } from './hex-position.js';

export class Point {
  constructor(x = 0, y = 0) {
    this.x = x;
    this.y = y;
  }

  static fromObject(object) {
    return new Point(object.x, object.y);
  }

  static fromAngle(degreesRotation, distance) {
    const rotation = degreesToRadians(degreesRotation);

    const x = Math.sin(rotation) * distance;
    const y = -Math.cos(rotation) * distance;

    return new Point(x, y);
  }

  sameAs(point) {
    if (!point) return false;
    return this.x === point.x && this.y === point.y;
  }

  rotate(rotation, centerX = 0, centerY = 0) {
    const xDelta = this.x - centerX;
    const yDelta = this.y - centerY;

    const length = Math.sqrt(Math.pow(xDelta, 2) + Math.pow(yDelta, 2));

    const newDirection = this.getRotation() - rotation;

    this.x = centerX + Math.sin(newDirection) * length;
    this.y = centerY + Math.cos(newDirection) * length;

    return this;
  }

  clone() {
    return new Point(this.x, this.y);
  }

  offset(offsetX, offsetY) {
    this.x += offsetX;
    this.y += offsetY;

    return this;
  }

  addScaled(point, scale = 1) {
    this.x += point.x * scale;
    this.y += point.y * scale;

    return this;
  }

  add(point) {
    this.x += point.x;
    this.y += point.y;

    return this;
  }

  scale(scale) {
    this.x *= scale;
    this.y *= scale;

    return this;
  }

  toHex() {
    const x = this.x - HEXAGON_HEIGHT;
    const y = this.y;

    const fracQ = ((Math.sqrt(3) / 3) * x - (1 / 3) * y) / HEXAGON_RADIUS;
    const fracR = ((2 / 3) * y) / HEXAGON_RADIUS;
    const fracS = -fracQ - fracR;

    let q = Math.round(fracQ);
    let r = Math.round(fracR);
    let s = Math.round(fracS);

    const q_diff = Math.abs(q - fracQ);
    const r_diff = Math.abs(r - fracR);
    const s_diff = Math.abs(s - fracS);

    if (q_diff > r_diff && q_diff > s_diff) {
      q = -r - s;
    } else if (r_diff > s_diff) {
      r = -q - s;
    } else {
      s = -q - r;
    }

    const col = q + (r + (r & 1)) / 2;
    const row = r;

    return new HexPosition(col, row);
  }

  subtract(point) {
    this.x -= point.x;
    this.y -= point.y;

    return this;
  }

  difference(point) {
    return point.clone().subtract(this);
  }

  getRotation() {
    return (Math.atan2(this.y, this.x) + rightAngleRadians + fullAngleRadians) % fullAngleRadians;
  }

  extend(distance) {
    const rotation = this.getRotation();

    this.x += Math.sin(rotation) * distance;
    this.y += -Math.cos(rotation) * distance;

    return this;
  }

  setDistance(distance) {
    const rotation = this.getRotation();

    this.x = Math.sin(rotation) * distance;
    this.y = -Math.cos(rotation) * distance;

    return this;
  }

  nullify() {
    this.x = 0;
    this.y = 0;

    return this;
  }

  isZero() {
    return this.x === 0 && this.y === 0;
  }

  round() {
    this.x = Math.round(this.x);
    this.y = Math.round(this.y);
  }

  addX(value) {
    this.x += value;

    return this;
  }

  addY(value) {
    this.y += value;

    return this;
  }

  set(x, y) {
    this.x = x;
    this.y = y;
  }

  length() {
    return Math.sqrt(Math.pow(this.x, 2) + Math.pow(this.y, 2));
  }

  distance(point) {
    return Math.floor(Math.sqrt(Math.pow(point.x - this.x, 2) + Math.pow(point.y - this.y, 2)));
  }

  toObject() {
    return { x: this.x, y: this.y };
  }
}
