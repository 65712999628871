import { Button } from '../button';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { openView } from 'store/temporary/slice';

import styles from './styles.module.css';

export function ResumeMatch() {
  const dispatch = useDispatch();

  const onClick = () => {
    dispatch(openView({ viewName: 'game-multiplayer' }));
  };

  return null;

  return (
    <div className={styles.container}>
      <p className={styles.text}>You have match in progress</p>
      <Button onClick={onClick}>Go back</Button>
    </div>
  );
}
