import { ENTITIES } from 'common/consts/types/entities.js';
import { IMAGES } from 'common/consts/types/images.js';
import { Point } from 'common/data-types/point.js';
import { EntityBase } from 'common/entities/entity-base.js';

const FAZING = 1;

export class Bullet extends EntityBase {
  constructor(params = {}) {
    super(params);

    this.position = params.position || new Point(params.x, params.y);
    this.speed = params.speed || new Point(params.xSpeed, params.ySpeed);

    this.lifeTime = params.lifeTime || null;

    this.owner = params.owner;

    this.power = params.power || 1;
    this.piercing = params.piercing || 1;

    this.leftFaze = false;

    this.type = ENTITIES.BULLET;
    this.socketable = true;

    const rotation = this.speed.getRotation();
    this.leftFazing = new Point(FAZING / 2, 0).rotate(rotation);
    this.rightFazing = new Point(-FAZING / 2, 0).rotate(rotation);

    if (this.interfacePart) this.createSprite();
  }

  createSprite() {
    this.sprite = this.game.texturesManager.createStandardSprite(IMAGES.ENTITIES.BULLET);
    this.registerMapSprite(this.sprite);

    this.sprite.zIndex = 2;
    this.sprite.tint = this.owner.color;

    this.setSpritePosition();
  }

  activate(maxDelta) {
    const delta = this.calculateDelta(maxDelta);

    this.position.addScaled(this.speed, delta);

    if (this.interfacePart) this.activateInterface();

    if (this.advancedLogicPart) {
      this.checkCollision();
    }

    this.calculateLifeTime(delta);
  }

  activateInterface() {
    this.setSpritePosition();
  }

  setSpritePosition() {
    this.sprite.x = this.position.x;
    this.sprite.y = this.position.y;
  }

  calculateDelta(delta) {
    if (this.lifeTime !== null) {
      if (this.lifeTime < delta) return this.lifeTime;
    }

    return delta;
  }

  calculateLifeTime(delta) {
    if (this.lifeTime === null) return false;
    if (this.lifeTime <= 0) return this.destroy();

    this.lifeTime -= delta;
  }

  checkCollision() {
    const hexPosition = this.position
      .clone()
      .add(this.leftFaze ? this.leftFazing : this.rightFazing)
      .toHex();
    this.leftFaze = !this.leftFaze;

    const field = this.game.mapController.getFieldByHex(hexPosition);

    if (!field) return false;
    if (!field.isCollisionable()) return false;
    if (field.isType(ENTITIES.WALL)) return this.destroy();

    if (!this.advancedLogicPart) return false;

    if (field.ownedBy(this.owner)) return false;

    this.power = field.getDamage(this.power, this.owner);
    this.piercing -= 1;

    if (this.piercing <= 0 || this.power <= 0) return this.destroy();
  }

  destroy() {
    super.destroy();
    if (this.interfacePart) this.removeMapSprite(this.sprite);
  }

  toSocketData() {
    return {
      type: this.type,
      id: this.id,
      position: { x: this.position.x, y: this.position.y },
      speed: { x: this.speed.x, y: this.speed.y },
      power: this.power,
      lifeTime: this.lifeTime,
      hostId: this.owner.id,
    };
  }
}
