import { BaseStructure } from '../base.js';

export class BaseBuilding extends BaseStructure {
  constructor(params) {
    super(params);

    this.isBuilding = true;
    this.demolitionable = true;
  }

  destroy() {
    this.game.eventsController.runEvent('beforeBuildingDestroy', { structure: this });
    super.destroy();
  }
}
