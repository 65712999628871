import * as PIXI from 'pixi.js';

import { withLeftClick } from 'client/utils/with-left-click.js';

import { IMAGES } from 'common/consts/types/images.js';

import { InterfaceBase } from '../interface-base.js';

export class HexButtonSprite extends InterfaceBase {
  constructor(params) {
    super(params);

    this.text = params.text;

    this.width = 50;
    this.height = 58;

    this.parentContainer = params.parentContainer;
  }

  setupSprites() {
    this.createContainer();

    this.createBackground();
    this.createText();

    this.setParentContainer(this.parentContainer);
  }

  onClick = () => {
    console.log('?');
  };

  createContainer() {
    super.createContainer();

    this.sprites.container.eventMode = 'static';
    this.sprites.container.cursor = 'pointer';
    this.sprites.container.on('pointerdown', withLeftClick(this.onClick));
  }

  createText() {
    this.sprites.text = this.game.texturesManager.createText(this.text, {
      fill: 'white',
      stroke: {
        width: 6,
        color: 'black',
        join: 'round',
      },
    });

    this.sprites.text.anchor.set(0.5, 0.5);

    this.sprites.container.addChild(this.sprites.text);
  }

  createBackground() {
    this.sprites.background = this.game.texturesManager.createSprite(IMAGES.INTERFACE.MARKET_BUTTON);

    this.sprites.background.anchor.set(0.5, 0.5);
    this.sprites.background.hitArea = new PIXI.Circle(0, 0, 28);

    this.sprites.container.addChild(this.sprites.background);
  }
}
