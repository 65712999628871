import { SOUNDS } from 'client/consts/sounds.js';
import { Z_INDEX } from 'client/consts/z-index.js';
import { Box } from 'client/interface/elements/box.js';
import { Button } from 'client/interface/elements/button.js';
import { Overlay } from 'client/interface/elements/overlay.js';
import { Slider } from 'client/interface/elements/slider.js';
import { Text } from 'client/interface/elements/text.js';
import { CentredInterfaceGroup } from 'client/interface/groups/centred-interface-group.js';

const BUTTON_TOP_MARGIN = 40;
const GAP = 5;
const PADDING = 10;

const TITLE_SIZE = 40;
const TEXT_SIZE = 20;

const WIDTH = 450;

export class SettingsMenu extends CentredInterfaceGroup {
  constructor(params) {
    super(params);

    this.width = WIDTH;

    this.gap = GAP;
    this.padding = PADDING;

    this.createSubElements();
    this.registerEventListeners();
  }

  toggle() {
    this.visible ? this.close() : this.show();
  }

  open() {
    this.setElementsPositions();
    this.setBoxSize();
    this.setVisibility(true);
  }

  close() {
    this.setVisibility(false);
  }

  registerEventListeners() {
    this.game.eventsController.addListener('escKeyDown', this);
  }

  onEscKeyDown() {
    if (!this.visible) return false;

    this.close();
    this.game.interfaceController.pauseMenu.open();

    return true;
  }

  createSubElements() {
    this.registerElement(
      'overlay',
      new Overlay({
        game: this.game,
        zIndex: Z_INDEX.MESSAGE_BOX,
      })
    );

    this.registerElement(
      'box',
      new Box({
        game: this.game,
        zIndex: Z_INDEX.MESSAGE_BOX,
      })
    );

    this.registerElement(
      'title',
      new Text({
        text: 'SETTINGS',
        game: this.game,
        zIndex: Z_INDEX.MESSAGE_BOX,
        width: this.width,
        fontSize: TITLE_SIZE,
      })
    );

    this.registerElement(
      'soundLabel',
      new Text({
        text: 'Sound Volume',
        game: this.game,
        zIndex: Z_INDEX.MESSAGE_BOX,
        width: this.width,
        fontSize: TEXT_SIZE,
      })
    );

    this.registerElement(
      'soundSlider',
      new Slider({
        startingValue: this.game.settingsController.getSoundVolume(),
        afterSliding: this.onSoundBarSlide,
        game: this.game,
        zIndex: Z_INDEX.MESSAGE_BOX,
        width: this.width,
      })
    );

    this.registerElement(
      'resumeButton',
      new Button({
        text: 'Back',
        callback: this.onBack,
        game: this.game,
        zIndex: Z_INDEX.MESSAGE_BOX,
        marginTop: BUTTON_TOP_MARGIN,
      })
    );
  }

  setBoxSize() {
    this.elements.box.setSize(this.width, this.height);
  }

  onSoundBarSlide = (value) => {
    this.game.settingsController.setSoundVolume(value);
    this.game.soundsController.playSound(SOUNDS.SELECTING_CARD, { soundIndex: 2 });
  };

  onBack = () => {
    this.close();
    this.game.interfaceController.pauseMenu.open();
  };

  afterResize() {
    if (!this.visible) return false;

    this.setElementsPositions();
  }
}
