import styles from './styles.module.css';

export function Button(props) {
  const { width, theme, themes } = props;

  const stylesOverride = {};

  if (width) stylesOverride.width = width;

  const classes = [styles.button];

  if (theme) classes.unshift(styles[theme]);
  if (themes) themes.forEach((theme) => classes.unshift(styles[theme]));

  return <button className={classes.join(' ')} style={stylesOverride} {...props} />;
}
