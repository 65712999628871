export class Timer {
  constructor() {
    this.startTime = new Date().getTime();

    this.offset = 0;
  }

  setOffsetByDiff(timestamp) {
    this.offset = timestamp - new Date().getTime();
  }

  setOffest(offset) {
    this.offset = offset;
  }

  passed() {
    const nowTime = new Date().getTime();

    return nowTime - this.startTime;
  }

  reset() {
    this.startTime = new Date().getTime();
  }

  getTimestamp() {
    return new Date().getTime() + this.offset;
  }

  static getTimestamp() {
    return new Date().getTime();
  }
}
