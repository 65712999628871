import { createSlice } from '@reduxjs/toolkit';

const temporarySlice = createSlice({
  name: 'temporary',
  initialState: {
    history: [],
  },
  reducers: {
    openView(state, action) {
      const { viewName, viewParams } = action.payload;

      const history = [...state.history, { viewName, viewParams }];

      if (history.length > 100) history.unshift();

      state.history = history;
    },
    resetToView(state, action) {
      const { viewName, viewParams } = action.payload;

      const history = [{ viewName, viewParams }];

      state.history = history;
    },
    goBackView(state) {
      const history = [...state.history];
      history.pop();

      state.history = history;
    },
  },
});

export const { openView, resetToView, goBackView } = temporarySlice.actions;

export default temporarySlice.reducer;
