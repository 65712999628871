import { TOOLTIP } from 'common/consts/tooltip.js';
import { ENTITIES } from 'common/consts/types/entities.js';
import { IMAGES } from 'common/consts/types/images.js';

import { EffectBase } from './effect-base.js';

export class VoidEffect extends EffectBase {
  constructor(params) {
    super({ ...params, priority: 1 });

    this.type = EFFECTS.VOID;
    this.tooltipTypes = [TOOLTIP.TYPE.VOID];

    this.power = params.power;

    if (this.interfacePart && this.visible) this.createSprites();
  }

  getIconName() {
    return IMAGES.ICONS.VOID;
  }

  updateTexts() {
    if (!this.hasSprite()) return false;

    this.sprites.text.text = this.power;
  }

  onCycleEnd() {
    this.doOneTick();
  }

  doOneTick() {
    if (!this.advancedLogicPart) return;
    this.kingdom.loseToVoid(this.power);
  }

  update(params) {
    const { power } = params;

    if (power !== undefined) this.power = power;

    this.updateTexts();
  }

  increase(params) {
    this.power += params.power;
    this.updateTexts();
  }

  merge(effect) {
    this.power += effect.power;
    this.updateTexts();
  }

  toSocketData() {
    return {
      id: this.id,
      type: this.type,
      power: this.power,
    };
  }
}
