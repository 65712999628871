import { Layout } from '../layout';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AboutView } from 'views/about';
import { AnnouncementView } from 'views/announcement';
import { GameView } from 'views/game';
import { HomeView } from 'views/home';
import { MultiplayerTypeView } from 'views/multiplayer-type';
import { PreparationCreatorView } from 'views/preparation-creator';
import { PreparationLaunchingView } from 'views/preparation-launching';
import { PreparationsView } from 'views/preparations';
import { SingleplayerSelectionView } from 'views/singleplayer-selection';
import { StatisticsView } from 'views/statistics';

import { setDevMode } from 'store/main/slice';
import { getLastView } from 'store/temporary/selectors';

export function Navigator() {
  const dispatch = useDispatch();
  const lastView = useSelector(getLastView);
  const { viewName, viewParams } = lastView;

  useEffect(() => {
    if (window.location.pathname === '/dev') dispatch(setDevMode({ devMode: true }));
    if (window.location.pathname === '/exit-dev') dispatch(setDevMode({ devMode: false }));
  }, []);

  function returnView() {
    switch (viewName) {
      case 'about': {
        return <AboutView />;
      }
      case 'game': {
        return <GameView viewParams={viewParams} />;
      }
      case 'multiplayer-type': {
        return <MultiplayerTypeView />;
      }
      case 'preparation-creator': {
        return <PreparationCreatorView />;
      }
      case 'preparation-launching': {
        return <PreparationLaunchingView viewParams={viewParams} />;
      }
      case 'preparations': {
        return <PreparationsView />;
      }
      case 'announcement': {
        return <AnnouncementView viewParams={viewParams} />;
      }
      case 'singleplayer-selection': {
        return <SingleplayerSelectionView viewParams={viewParams} />;
      }
      case 'statistics': {
        return <StatisticsView />;
      }
      default:
        return <HomeView />;
    }
  }

  return <Layout>{returnView()}</Layout>;
}
