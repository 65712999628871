import { STRUCTURES } from 'common/consts/types/structures.js';

export function sortFieldsByWeakest(fieldA, fieldB) {
  if (fieldA.structure && fieldA.structure.type === STRUCTURES.HEADQUARTER) return 1;
  if (fieldB.structure && fieldB.structure.type === STRUCTURES.HEADQUARTER) return -1;

  if (fieldA.structure && !fieldB.structure) return 1;
  if (!fieldA.structure && fieldB.structure) return -1;

  if (fieldA.fortification !== fieldB.fortification) return fieldA.fortification - fieldB.fortification;

  return fieldB.distance - fieldA.distance;
}
