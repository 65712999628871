export const HEXAGON_HEIGHT = 50;
export const HEXAGON_RADIUS = (HEXAGON_HEIGHT * 2) / Math.sqrt(3);
export const HEXAGON_SIZE = HEXAGON_HEIGHT * 2;

export const REAL_CARD_WIDTH = 400;
export const REAL_CARD_HEIGHT = 560;

export const CARD_SCALE = 150 / 400;

export const CARD_WIDTH = REAL_CARD_WIDTH * CARD_SCALE;
export const CARD_HEIGHT = REAL_CARD_HEIGHT * CARD_SCALE;

export const MARGIN = 10;

export const RESOURCE_ICON_SIZE = 40;
export const RESOURCES_GAP = MARGIN;
export const RESOURCE_TEXT_SIZE = 30;

export const RESEARCH_BUTTONS_FONT_SIZE = 30;

export const DECK_HEIGHT = CARD_HEIGHT;
export const DECK_WIDTH = CARD_WIDTH;

export const REDRAW_DOT_SIZE = 24;
export const REDRAW_ICONS_GAP = MARGIN;
export const REDRAW_ICONS_WIDTH = CARD_WIDTH;
export const REDRAW_ICONS_HEIGHT = REDRAW_DOT_SIZE * 2 + REDRAW_ICONS_GAP;

export const DISCARD_WIDTH = CARD_WIDTH;
export const DISCARD_HEIGHT = CARD_HEIGHT;

export const CYCLE_BAR_WIDTH = 400;
export const CYCLE_BAR_HEIGHT = 30;
export const CYCLE_BAR_BORDER = 2;

export const EFFECTS_GAP = MARGIN;
export const EFFECTS_ICON_SIZE = 50;
export const EFFECTS_BAR_HEIGHT = 50;

export const TOP_INFO_SIZE = 30;

// ---------- TOP ----------

export const CYCLE_BAR_TOP = MARGIN;
export const EFFECTS_BAR_TOP = CYCLE_BAR_TOP + CYCLE_BAR_HEIGHT + MARGIN;
export const TOP_INFO_TOP = EFFECTS_BAR_TOP + EFFECTS_BAR_HEIGHT + MARGIN;
export const RESEARCHER_TOP = TOP_INFO_TOP + TOP_INFO_SIZE + MARGIN;

// ---------- BOTTOM ----------

// HAND

export const HAND_HEIGHT = CARD_HEIGHT;
export const FOCUSED_CARD_OFFSET = 20;
export const HIDDEN_HAND_OFFSET = HAND_HEIGHT - 20 + MARGIN;

// HAND INFO

export const CANCEL_BUTTON_HEIGHT = 25;

// ACTIVE

export const ACTIVE_HEIGHT = CARD_HEIGHT;

// POSITIONS

export const HAND_BOTTOM = MARGIN;
export const CANCEL_BUTTON_BOTTOM = HAND_BOTTOM + HAND_HEIGHT + MARGIN;
export const ACTIVE_BOTTOM = CANCEL_BUTTON_BOTTOM + CANCEL_BUTTON_HEIGHT + MARGIN;

export const DECK_BOTTOM = MARGIN;
export const REDRAW_ICONS_BOTTOM = DECK_BOTTOM + DECK_HEIGHT + MARGIN;

export const DISCARD_BOTTOM = MARGIN;

export const RESOURCES_BOTTOM = REDRAW_ICONS_BOTTOM + REDRAW_ICONS_HEIGHT + MARGIN * 2;

// ---------- LEFT ----------

export const DECK_LEFT = MARGIN;
export const REDRAW_ICONS_LEFT = MARGIN;

export const RESOURCES_LEFT = MARGIN;

// ---------- RIGHT ----------

export const DISCART_RIGHT = MARGIN;

// MARGINS

export const HAND_MARGINS = DECK_LEFT + DECK_WIDTH + MARGIN + DISCART_RIGHT + DISCARD_WIDTH + MARGIN;
export const RESEARCH_MARGINS = CARD_WIDTH;
