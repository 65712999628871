import { CARDS } from 'common/consts/types/cards.js';

import { EnemyKingdom } from '../enemy.js';

export class RandomCannonKingdom extends EnemyKingdom {
  onPlayTimer() {
    this.tryPlayRandom();
  }

  startingCards() {
    const cards = [
      this.createCard({ type: CARDS.JUNK }),
      this.createCard({ type: CARDS.JUNK }),
      this.createCard({ type: CARDS.JUNK }),
      this.createCard({ type: CARDS.JUNK }),
      this.createCard({ type: CARDS.JUNK }),
      this.createCard({ type: CARDS.JUNK }),
      this.createCard({ type: CARDS.JUNK }),
      this.createCard({ type: CARDS.JUNK }),
      this.createCard({ type: CARDS.JUNK }),
      this.createCard({ type: CARDS.JUNK }),
      this.createCard({ type: CARDS.JUNK }),
      this.createCard({ type: CARDS.PLACE_RANDOM_CANNON_RANDOMLY }),
      this.createCard({ type: CARDS.SPREAD_SHOT }),
      this.createCard({ type: CARDS.SPREAD_SHOT }),
      this.createCard({ type: CARDS.FORTIFICATE_MAIN }),
      this.createCard({ type: CARDS.RANDOM_FORTIFICATION }),
      this.createCard({ type: CARDS.ADD_ENERGY }),
      this.createCard({ type: CARDS.FORTIFICATE_MAIN }),
    ];

    if (this.difficulty >= 2) cards.push(this.createCard({ type: CARDS.FORTIFICATE_MAIN }));
    if (this.difficulty >= 2) cards.push(this.createCard({ type: CARDS.DRAW_CARDS }));
    if (this.difficulty >= 2) cards.push(this.createCard({ type: CARDS.RANDOM_FORTIFICATION }));
    if (this.difficulty >= 2) cards.push(this.createCard({ type: CARDS.PLACE_POWER_STATION }));
    if (this.difficulty >= 2) cards.push(this.createCard({ type: CARDS.ADD_ENERGY }));

    if (this.difficulty >= 3) cards.push(this.createCard({ type: CARDS.PLACE_RANDOM_CANNON_RANDOMLY }));
    if (this.difficulty >= 3) cards.push(this.createCard({ type: CARDS.FORTIFICATE_MAIN }));
    if (this.difficulty >= 3) cards.push(this.createCard({ type: CARDS.FORTIFICATE_AROUND_BASE }));
    if (this.difficulty >= 3) cards.push(this.createCard({ type: CARDS.JUNK }));
    if (this.difficulty >= 3) cards.push(this.createCard({ type: CARDS.JUNK }));
    if (this.difficulty >= 3) cards.push(this.createCard({ type: CARDS.JUNK }));
    if (this.difficulty >= 3) cards.push(this.createCard({ type: CARDS.JUNK }));

    return cards;
  }
}
