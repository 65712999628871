import { Z_INDEX } from 'client/consts/z-index.js';

import { IMAGES } from 'common/consts/types/images.js';
import { EntityBase } from 'common/entities/entity-base.js';
import { perFrameTimer } from 'common/helpers/converters.js';

const LIFE_TIME = perFrameTimer(1);

export class ExpandingRing extends EntityBase {
  constructor(params) {
    super(params);

    this.position = params.position;

    this.lifeTime = LIFE_TIME;

    this.sprite = this.game.texturesManager.createStandardSprite(IMAGES.PARTICLES.BUILDING_AURA);
    this.registerMapSprite(this.sprite);

    this.sprite.x = this.position.x;
    this.sprite.y = this.position.y;

    this.sprite.zIndex = Z_INDEX.PARTICLES;

    if (params.kingdom) this.sprite.tint = params.kingdom.color;
    this.sprite.scale.set(0);
  }

  activate(delta) {
    this.calculateLifeTimer(delta);
    this.calculateScale();
  }

  calculateLifeTimer(delta) {
    this.lifeTime -= delta;

    if (this.lifeTime <= 0) this.destroy();
  }

  calculateScale() {
    this.sprite.scale.set(1 - this.lifeTime / LIFE_TIME);
  }

  destroy() {
    super.destroy();
    this.removeMapSprite(this.sprite);
  }
}
