import { HexButtonSprite } from './hex.js';

export class FixedCardsButton extends HexButtonSprite {
  constructor(params) {
    super({ ...params, text: 'F' });

    this.setupSprites(params);
  }

  onClick = () => {
    this.game.interfaceController.onMarketButtonClick('fixed');
  };
}
