export class EntityBase {
  constructor(params) {
    this.id = params.id || params.game.generateId();
    this.game = params.game;

    this.priority = params.priority || 2;

    this.interfacePart = params.game.interfacePart;
    this.advancedLogicPart = params.game.advancedLogicPart;
    this.serverPart = params.game.serverPart;
    this.multiplayerPart = params.game.multiplayerPart;

    this.game.entitiesController.registerEntity(this);

    this.destroyed = false;
  }

  getId() {
    return this.id;
  }

  activate() {}

  onCycleEnd() {}

  destroy() {
    this.game.entitiesController.removeEntity(this);
    this.destroyed = true;
  }

  removeSprite(sprite) {
    this.game.interfaceController.removeSprite(sprite);
  }

  registerSprite(sprite) {
    this.game.interfaceController.registerSprite(sprite);
  }

  removeSprite(sprite) {
    this.game.interfaceController.removeSprite(sprite);
  }

  registerMapSprite(sprite) {
    this.game.interfaceController.registerMapSprite(sprite);
  }

  removeMapSprite(sprite) {
    this.game.interfaceController.removeMapSprite(sprite);
  }

  registerMapInterfaceSprite(sprite) {
    this.game.interfaceController.registerMapInterfaceSprite(sprite);
  }

  removeMapInterfaceSprite(sprite) {
    this.game.interfaceController.removeMapInterfaceSprite(sprite);
  }

  afterResize() {}

  onZoom() {}

  idToSocketData() {
    return { id: this.id };
  }
}
