import { useDispatch } from 'react-redux';

import { Button } from 'components/common/button';
import { CentredContainer } from 'components/common/centred-container';

import { openView } from 'store/temporary/slice';

import styles from './styles.module.css';

export function AnnouncementView({ viewParams }) {
  const dispatch = useDispatch();

  const { title, message } = viewParams;

  const onBackClick = () => {
    dispatch(openView({ viewName: 'home' }));
  };

  return (
    <CentredContainer>
      <p className={styles.title}>{title}</p>
      <p className={styles.description}>{message}</p>

      <div className={styles.buttonWrapper}>
        <Button onClick={onBackClick}>Home</Button>
      </div>
    </CentredContainer>
  );
}
