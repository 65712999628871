import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Button } from 'components/common/button';
import { CentredContainer } from 'components/common/centred-container';
import { LoadingOverview } from 'components/common/loading-overview';
import { PreparationForm } from 'components/common/preparation-form';
import { Title } from 'components/common/title';

import { openView, resetToView } from 'store/temporary/slice';

import { PreparationParams } from './preparation-params';
import { PreparationPlayers } from './preparation-players';

import styles from './styles.module.css';

export function PreparationLaunchingView({ viewParams }) {
  const dispatch = useDispatch();

  const connection = useSelector((state) => state.websocket.connection);
  const { id: playerId, token: playerToken, nick: playerNick } = useSelector((state) => state.main.player);

  const { preparationId } = viewParams;

  const [preparation, setPreparation] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [showForm, setShowForm] = useState(false);

  const onJoin = (response) => {
    setIsLoading(false);
    setPreparation(response.preparation);
  };

  useEffect(() => {
    connection.emit('client:joinPreparation', { id: preparationId }, onJoin);
    connection.emit('client:subscribeToPreparation', { id: preparationId });

    connection.on('server:updatePreparation', onUpdatePreparation);
    connection.on('server:launchPreparation', onLaunchPreparation);

    return () => {
      connection.emit('client:leaveCurrentPreparation');
      connection.emit('client:unsubscribeToPreparation', { id: preparationId });

      connection.off('server:updatePreparation', onUpdatePreparation);
      connection.off('server:launchPreparation', onLaunchPreparation);
    };
  }, []);

  const onUpdatePreparation = (response) => {
    setPreparation(response.preparation);
  };

  const onLaunchPreparation = (response) => {
    dispatch(resetToView({ viewName: 'game', viewParams: { isMultiplayer: true, preparation: response.preparation } }));
  };

  const onStartClick = () => {
    connection.emit('client:starCurrentPreparation', (response) => {
      dispatch(resetToView({ viewName: 'game', viewParams: { isMultiplayer: true, isHost: true, preparation: response.preparation } }));
    });
  };

  const onLeaveClick = () => {
    dispatch(resetToView({ viewName: 'home' }));
  };

  const onEditClick = () => {
    setShowForm(true);
  };

  const onUpdateParams = (params) => {
    connection.emit('client:updatePreparation', { ...params, id: preparationId });
    setShowForm(false);
  };

  const onCancelEdit = () => {
    setShowForm(false);
  };

  const isHost = preparation && preparation.hostId === playerId;

  return (
    <CentredContainer>
      {isLoading && <LoadingOverview />}
      {!isLoading && (
        <div className={styles.container}>
          {!showForm && (
            <div className={styles.detailsContainer}>
              <PreparationParams preparation={preparation} showEdit={isHost} onEditClick={onEditClick} />

              <div className={styles.buttonsContainer}>
                {isHost && <Button onClick={onStartClick}>Start</Button>}
                {isHost && <Button onClick={onEditClick}>Edit</Button>}
                <Button onClick={onLeaveClick} theme="abort">
                  Leave
                </Button>
              </div>
              <PreparationPlayers preparation={preparation} />
            </div>
          )}
          {showForm && (
            <>
              <Title>Update the match</Title>

              <PreparationForm
                params={preparation}
                onSubmit={onUpdateParams}
                buttonLabel="Update"
                secondButton={
                  <Button onClick={onCancelEdit} theme="abort">
                    Cancel
                  </Button>
                }
              />
            </>
          )}
        </div>
      )}
    </CentredContainer>
  );
}
