import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { BackButton } from 'components/common/back-button';
import { Button } from 'components/common/button';
import { CentredContainer } from 'components/common/centred-container';
import { Subtitle } from 'components/common/subtitle';
import { TextInput } from 'components/common/text-input';
import { Title } from 'components/common/title';

import { setPlayerData } from 'store/main/slice';
import { openView } from 'store/temporary/slice';

import styles from './styles.module.css';

export function MultiplayerTypeView() {
  const dispatch = useDispatch();

  const connection = useSelector((state) => state.websocket.connection);

  const { nick: previousNick } = useSelector((state) => state.main.player);

  const [nick, setNick] = useState(previousNick || '');

  const onQuickJoinClick = () => {
    connection.emit('client:quickJoin', (response) => {
      dispatch(openView({ viewName: 'preparation-launching', viewParams: { preparationId: response.preparation.id } }));
    });
  };

  const onBrowseGamesClick = () => {
    dispatch(openView({ viewName: 'preparations' }));
  };

  const onCreateNewGameClick = () => {
    dispatch(openView({ viewName: 'preparation-creator' }));
  };

  const onClickNickSave = () => {
    connection.emit('client:updatePlayer', { nick }, (response) => {
      dispatch(setPlayerData({ player: response.player }));
    });
  };

  return (
    <CentredContainer>
      <Title>Multiplayer</Title>
      <Subtitle>(alpha - have mercy)</Subtitle>

      <div className={`${styles.section} ${styles.settingsSection}`}>
        <div className={styles.inputWrapper}>
          <label className={styles.label}>Nick:</label>

          <TextInput placeholder="nick" value={nick} onChange={(text) => setNick(text)} />

          {previousNick !== nick && <Button onClick={onClickNickSave}>Save</Button>}
        </div>
      </div>

      <div className={styles.section}>
        <div className={styles.buttonGroupWrapper}>
          <Button onClick={onQuickJoinClick}>Quick join</Button>
        </div>

        <div className={styles.buttonGroupWrapper}>
          <Button onClick={onBrowseGamesClick}>Browser games</Button>
        </div>

        <div className={styles.buttonGroupWrapper}>
          <Button onClick={onCreateNewGameClick}>Create a game</Button>
        </div>
      </div>

      <div className={styles.backButtonWrapper}>
        <BackButton />
      </div>
    </CentredContainer>
  );
}
