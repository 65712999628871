import { Sequencer } from 'common/data-types/sequencer.js';

export const generateId = (() => {
  const idSequencer = new Sequencer();

  return function () {
    return idSequencer.next();
  };
})();

export function cloneObject(object) {
  return JSON.parse(JSON.stringify(object));
}

const prefixes = {};
export function uniqueString(prefix = '') {
  if (!prefixes[prefix]) prefixes[prefix] = 0;
  prefixes[prefix]++;
  return prefix + prefixes[prefix];
}

export function objectToArray(object) {
  const array = [];

  for (const key in object) {
    array.push(object[key]);
  }

  return array;
}
