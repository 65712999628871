import { COLORS } from 'common/consts/colors.js';
import { CARDS } from 'common/consts/types/cards.js';
import { EFFECTS } from 'common/consts/types/effects.js';
import { IMAGES } from 'common/consts/types/images.js';

import { TOOLTIP } from '../consts/tooltip.js';

export const FIXED_CARDS = {
  [CARDS.PLACE_POWER_STATION]: {
    type: CARDS.PLACE_POWER_STATION,
    cost: 100,
    title: 'Power station',
    image: IMAGES.CARDS.IMAGES.PLACE_POWER_STATION,
    effects: [EFFECTS.DRAIN, EFFECTS.PLACE_POWER_STATION],
    stats: {
      drain: 700,
    },
    color: COLORS.CARD.GREEN,
    tooltipTypes: [TOOLTIP.TYPE.DRAIN, TOOLTIP.TYPE.POWER_STATION],
  },
  [CARDS.ADD_ENERGY]: {
    type: CARDS.ADD_ENERGY,
    cost: 400,
    title: 'Power up',
    image: IMAGES.CARDS.IMAGES.ADD_ENERGY,
    effects: [EFFECTS.ADD_ENERGY],
    stats: {
      energy: 200,
    },
    color: COLORS.CARD.GREEN,
  },
  [CARDS.PLACE_BASIC_CANNON]: {
    type: CARDS.PLACE_BASIC_CANNON,
    cost: 100,
    title: 'Basic cannon',
    image: IMAGES.CARDS.IMAGES.PLACE_BASIC_CANNON,
    effects: [EFFECTS.DRAIN, EFFECTS.PLACE_BASIC_CANNON],
    stats: {
      drain: 300,
    },
    color: COLORS.CARD.RED,
    tooltipTypes: [TOOLTIP.TYPE.DRAIN, TOOLTIP.TYPE.BASIC_CANNON],
  },
  [CARDS.PLACE_SNIPER_CANNON]: {
    type: CARDS.PLACE_SNIPER_CANNON,
    cost: 200,
    title: 'Sniper cannon',
    image: IMAGES.CARDS.IMAGES.PLACE_SNIPER_CANNON,
    effects: [EFFECTS.DRAIN, EFFECTS.PLACE_SNIPER_CANNON],
    stats: {
      drain: 900,
    },
    color: COLORS.CARD.RED,
    tooltipTypes: [TOOLTIP.TYPE.DRAIN, TOOLTIP.TYPE.SNIPER_CANNON],
  },
  [CARDS.FORTIFICATE_AND_PLACE_REINFORCEMENT]: {
    type: CARDS.FORTIFICATE_AND_PLACE_REINFORCEMENT,
    cost: 100,
    title: 'Reinforce',
    image: IMAGES.CARDS.IMAGES.FORTIFICATE_AND_PLACE_REINFORCEMENT,
    effects: [EFFECTS.DESTROY, EFFECTS.FORTIFICATE_AND_PLACE_REINFORCEMENT],
    stats: {
      fortification: 15,
    },
    color: COLORS.CARD.BLUE,
    tooltipTypes: [TOOLTIP.TYPE.FORTIFICATION, TOOLTIP.TYPE.REINFORCEMENT],
  },
  [CARDS.PLACE_FORGE]: {
    type: CARDS.PLACE_FORGE,
    cost: 100,
    title: 'Forge',
    image: IMAGES.CARDS.IMAGES.PLACE_FORGE,
    effects: [EFFECTS.DRAIN, EFFECTS.PLACE_FORGE],
    stats: {
      drain: 300,
    },
    color: COLORS.CARD.BLUE,
    tooltipTypes: [TOOLTIP.TYPE.DRAIN, TOOLTIP.TYPE.FORGE],
  },
  [CARDS.IMPROVE_HEADQUARTER]: {
    type: CARDS.IMPROVE_HEADQUARTER,
    cost: 10000,
    title: 'Slow Creeping',
    image: IMAGES.CARDS.IMAGES.IMPROVE_HEADQUARTER,
    effects: [EFFECTS.DESTROY, EFFECTS.IMPROVE_HEADQUARTER],
    color: COLORS.CARD.RED,
    stats: {
      level: 1,
    },
    tooltipTypes: [TOOLTIP.TYPE.PIERCING, TOOLTIP.TYPE.POWER, TOOLTIP.TYPE.SPREAD, TOOLTIP.TYPE.INACCURACY],
  },
};
