import { createSlice } from '@reduxjs/toolkit';
import Config from 'helpers/config.js';
import { makeRequest } from 'helpers/request';

const mainSlice = createSlice({
  name: 'main',
  initialState: {
    player: {},
    devMode: false,
    devToken: '',
  },
  reducers: {
    setPlayerData(state, action) {
      state.player = { ...state.player, ...action.payload.player };
    },
    setDevMode(state, action) {
      state.devMode = action.payload.devMode;
    },
    setDevToken(state, action) {
      state.devToken = action.payload.devToken;
    },
  },
});

export const { setPlayerData, setDevMode, setDevToken } = mainSlice.actions;

export async function getStatistics(args) {
  const { token, callback } = args;
  const response = await makeRequest({ url: Config.serverUrl + '/stats', token });
  callback(response);
}

export default mainSlice.reducer;
