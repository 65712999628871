import * as PIXI from 'pixi.js';

import { CANCEL_BUTTON_BOTTOM, CANCEL_BUTTON_HEIGHT, HIDDEN_HAND_OFFSET } from 'client/consts/layout.js';
import { Z_INDEX } from 'client/consts/z-index.js';
import { withLeftClick } from 'client/utils/with-left-click.js';

import { InterfaceBase } from '../interface-base.js';

export class CancelButton extends InterfaceBase {
  constructor(params) {
    super(params);

    this.parent = params.parent;

    this.visible = false;

    this.size = CANCEL_BUTTON_HEIGHT;

    this.createSprites();
    this.updatePosition();
    this.registerEventListeners();
  }

  createSprites() {
    this.createSpritesContainer();
    this.createTextSprites();
  }

  createSpritesContainer() {
    this.spritesContainer = new PIXI.Container();
    this.spritesContainer.zIndex = Z_INDEX.CANCEL_BUTTON;
    this.registerSprite(this.spritesContainer);
  }

  registerEventListeners = () => {
    this.game.eventsController.addListener('escKeyDown', this);
  };

  createTextSprites() {
    this.text = new PIXI.Text({
      text: 'Cancel',
      style: {
        fontFamily: 'Arial',
        fontSize: this.size,
        fill: 0xffffff,
        dropShadow: true,
        dropShadowBlur: 6,
        dropShadowDistance: 0,
      },
    });
    this.text.x = 0;
    this.text.y = 0;
    this.text.anchor.set(0.5);

    this.text.eventMode = 'static';
    this.text.cursor = 'pointer';

    this.text.on('pointerdown', withLeftClick(this.onClick));

    this.spritesContainer.addChild(this.text);

    this.spritesContainer.visible = false;
  }

  setVisible(value) {
    if (value === this.visible) return false;

    this.visible = value;
    this.spritesContainer.visible = value;
  }

  updatePosition() {
    const hiddenMode = this.parent.getFocusedOnBoard();

    const hiddenOffset = hiddenMode ? HIDDEN_HAND_OFFSET : 0;

    this.spritesContainer.x = window.innerWidth / 2;
    this.spritesContainer.y = window.innerHeight - CANCEL_BUTTON_BOTTOM - this.size / 2 + hiddenOffset;
  }

  onEscKeyDown = () => {
    if (!this.visible) return false;

    this.parent.onClickCancel();

    return true;
  };

  onFocusedOnBoardChange = () => {
    this.updatePosition();
  };

  afterResize() {
    this.updatePosition();
  }

  onClick = (event) => {
    this.parent.onClickCancel();
  };
}
