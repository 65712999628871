import { HexButtonSprite } from './hex.js';

export class RandomCardsButton extends HexButtonSprite {
  constructor(params) {
    super({ ...params, text: 'R' });

    this.setupSprites(params);
  }

  onClick = () => {
    this.game.interfaceController.onMarketButtonClick('random');
  };
}
